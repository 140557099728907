import $ from 'jquery'
import {
  type CombinedModelConstructorOptions,
  type ModelSetOptions
} from 'backbone'
import { Associations } from 'backbone-associations'
import { error as logError } from '@/utils/logging'
import ErrorMessageUtils from '@/modals/utils/ErrorMessageUtils'
import CanonicalCategory from '@/catalog/models/canonicalCategory'
import { getPriceAtQuantity } from '@/utils/itemUtils'
import { type Item as ItemAttributes } from '@/api/items'

interface ItemInitializeOptions {
  preventFetchAtInit?: boolean
}

export default class Item extends Associations.AssociatedModel<
  Partial<ItemAttributes>,
  ModelSetOptions,
  ItemInitializeOptions
> {
  fetchJqXhr?: JQuery.Deferred<void>

  // Configure properties early, so they're visible to all initialize logic,
  // including backbone-associations relations related to initial data.
  preinitialize() {
    this.relations = [
      {
        type: Associations.One,
        key: 'canonicalCategory',
        relatedModel: CanonicalCategory
      }
    ]
    this.urlRoot = '/api/v2/items'
  }

  defaults() {
    return { discountPercentage: 0 }
  }

  constructor(
    attributes?: Partial<ItemAttributes>,
    options: CombinedModelConstructorOptions<ItemInitializeOptions> = {}
  ) {
    super(attributes, options)
    // If the 'preventFetchAtInit' option is true, don't fetch the item model during initialization
    if (!this.isLoaded() && !options.preventFetchAtInit) {
      // Run in constructor instead of `initialize` to prevent Babel from overwriting fetchJqXhr
      this.fetchData()
    }
  }

  isLoaded() {
    // Assume this is a required attribute and that its presence implies that the model has been fetched
    return this.get('hugeImageUrl') !== undefined
  }

  fetchData() {
    return (this.fetchJqXhr = this.fetch() as unknown as JQueryDeferred<void>)
  }

  ready() {
    // Store the jqXHR and retry if ready() was called after a failure
    return (this.fetchJqXhr = this.fetchJqXhr!.fail(() => this.fetchData()))
  }

  getPriceAtQuantity(targetQuantity: number) {
    const prices = this.get('prices')
    // Initialization may trigger a lookup here for Backbone models, but `prices` won't exist to begin with
    // because data is loaded async.
    if (prices != null) {
      return getPriceAtQuantity(
        prices,
        this.get('priceQuantities')!,
        targetQuantity
      )
    }
  }

  getDiscountedPriceAtQuantity(targetQuantity: number) {
    return (
      this.getPriceAtQuantity(targetQuantity)! *
      (this.get('discountPercentage')! / 100)
    )
  }

  // Returns the initial quantity an item should by default.
  // This is the default displayed in a dropdown.
  getDefaultQuantity() {
    if (this.get('minQuantity')! <= 0) {
      return this.get('quantityIncrement')
    } else {
      return this.get('minQuantity')
    }
  }

  fetchByVendorItemIdAndVendorThemeId(
    vendorItemId: string,
    vendorThemeId: string
  ) {
    // Fetch the item by its vendor item id and vendor theme id
    const params = $.param({
      vendorItemId,
      vendorThemeId
    })

    return (this.fetchJqXhr = $.ajax({
      type: 'get',
      url: `/api/v2/items?${params}`,
      dataType: 'json'
    })
      .then((item) => this.set(item))
      .fail(function (jqXhr) {
        const errorMessage = ErrorMessageUtils.format(
          ErrorMessageUtils.errors.getItemByVendorItemIdAndVendorThemeId
        )
        logError(errorMessage, { jqXhr })
      }) as unknown as JQueryDeferred<void>)
  }

  isPhotoBookItemType() {
    return this.get('format')?.type === 'Photo Book'
  }

  isFramedPrintItemType() {
    return this.get('format')?.type === 'Framed Print'
  }

  isAddOnItemType() {
    return this.get('format')?.type === 'Add-On'
  }
}
