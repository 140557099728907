import { useMercury } from '@/api/mercury/utils'
import $ from 'jquery'

const isMercury = useMercury()

/**
 * SPA-like app rendered from Jade. Supports both Mercury and Avetti.
 */
const ECO_CART_PATH = '/eco/cart'

/**
 * Legacy Avetti-only cart path, to be replaced by `ECO_CART_PATH` in the future.
 * Page contains inlined bootstrapped cart data from backend.
 */
const AVETTI_CART_PATH = '/cards/editCartItems.html'

export const cartPagePath = isMercury ? ECO_CART_PATH : AVETTI_CART_PATH

export const isCartPage = (): boolean =>
  location.pathname === ECO_CART_PATH || location.pathname === AVETTI_CART_PATH

export function initializeCartTransitionUtils() {
  // TODO: Before launch, update SSR header links in Railroad
  $('#header a[href="/cards/editCartItems.html"]').attr('href', cartPagePath)
}

export function goToCartPage(params = '') {
  window.location.href = cartPagePath + params
}
