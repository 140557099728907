import _ from 'underscore'
import marketo from '@/analytics/marketo'
import { CanonicalCategory } from '@/analytics/types'
import { isCartPage } from '@/utils/cartTransitionUtils'

export const getPageData = (): PageContext => window._mktoPageData || {}

export const setPageData = (pageData: PageContext): PageContext => {
  window._mktoPageData = { ...getPageData(), ...pageData }
  marketo.trackPageData()
  return window._mktoPageData
}

export const getPageType = (): string => {
  const pageData = getPageData()
  if (pageData.pageType) {
    return pageData.pageType
  } else {
    const path = window.location.pathname
    if (path === '/') {
      return 'home'
    } else if (/^\/eco\/(.*c-\d+\.html|s\/[^/]+_c-\d+)$/.test(path)) {
      return 'category'
    } else if (path.indexOf('/eco/s/') === 0) {
      return 'search'
    } else if (/^\/eco\/.*p-\d+\.html$/.test(path)) {
      return 'product'
    } else if (isCartPage()) {
      return 'cart'
    } else if (path === '/cards/ordercompleted.html') {
      return 'purchase'
    } else if (
      path !== '/how-paper-culture-compares.html' &&
      document.getElementById('layout-landing') !== null
    ) {
      return 'landing'
    } else if (path === '/eco/my-account') {
      return 'account'
    } else if (
      path === '/eco/personalize' ||
      path.indexOf('/eco/personalize/editor/') === 0
    ) {
      return 'personalize'
    } else {
      return 'other'
    }
  }
}

type BackboneCanonicalCategory = {
  get: (property: 'majorCategory' | 'minorCategory') => string | undefined
}

/**
 * Properties that shouldn't be removed when resetting the page data. For example, if the item has been updated, this
 * means the canonical category might need to be updated, but the page type should stay the same.
 */
const allowlistedPageContextOnCategoryUpdate = [
  'pageType',
  'exp',
  'expActive',
  'user'
]

export const setPageCategory = (
  canonicalCategory?: CanonicalCategory | BackboneCanonicalCategory
): PageContext => {
  // Clear out all properties from the page data except allowlisted properties
  window._mktoPageData = _.pick(
    getPageData(),
    ...allowlistedPageContextOnCategoryUpdate
  )

  if (!canonicalCategory) {
    return getPageData()
  }

  let majorCategory
  let minorCategory
  if ('majorCategory' in canonicalCategory) {
    majorCategory = canonicalCategory.majorCategory || undefined
    minorCategory = canonicalCategory.minorCategory || undefined
  } else if (typeof canonicalCategory.get === 'function') {
    // If canonicalCategory isn't an object, see if it's a Backbone model
    majorCategory = canonicalCategory.get('majorCategory')
    minorCategory = canonicalCategory.get('majorCategory')
  }

  return setPageData({
    majorCategory,
    minorCategory
  })
}
