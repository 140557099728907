// TODO: Find a better way to organize this, userProfile, and other session or profile related utilities
// Separate from userProfile to avoid circular dependencies
import cookies from '@/utils/cookies'

/**
 * Attempts to get the user's email address from a variety of cookies. The user may have given
 * their email address during lead gen or when they signed in.
 *
 * @param includeEcpEmail If set to false, this won't return an email if it was collected
 * via email collection modal / popup (ECP). The returned email will be a user who previously
 * signed in who is therefore expected to already have a Paper Culture account.
 */
export default function (includeEcpEmail) {
  let userEmail
  if (cookies.hasItem('customer_login_cookie')) {
    userEmail = cookies.getItem('customer_login_cookie').replace(/"/g, '')
  } else if (cookies.hasItem('rememberedEmail')) {
    userEmail = cookies.getItem('rememberedEmail')
  } else if (cookies.hasItem('autoLoginName')) {
    userEmail = cookies.getItem('autoLoginName').replace(/"/g, '')
  } else if (includeEcpEmail !== false && cookies.hasItem('ecpEmail')) {
    userEmail = cookies.getItem('ecpEmail')
  }
  return userEmail
}
