import { SeverityLevel } from '@sentry/browser'

// TODO: Remove once TSConfig is updated to a modern version that knows about cause
declare global {
  interface Error {
    cause?: unknown
  }
}

export type CustomErrorOptions = {
  /**
   * Message to be displayed to the user.
   *
   * This may include user-friendly details on how to resolve the issue,
   * while maintaining technical detail in the `message`.
   */
  userMessage?: string
  severity?: SeverityLevel
}

export class CustomError extends Error {
  readonly options: CustomErrorOptions
  constructor(
    name: string,
    message: string,
    cause?: unknown,
    options: CustomErrorOptions = {}
  ) {
    super(message)
    this.name = name
    this.cause = cause
    this.options = options
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class UnexpectedError extends CustomError {
  constructor(cause: unknown) {
    super(
      'UnexpectedError',
      'There is a unexpected error, please try again later.',
      cause
    )
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class InvalidCredentials extends CustomError {
  constructor() {
    super('InvalidCredentials', 'Password or email is not correct')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class DuplicatedAccount extends CustomError {
  constructor() {
    super(
      'DuplicatedAccount',
      'There is already an account with the same email address.'
    )
  }
}

export class ErrorParsingResponse extends CustomError {
  constructor(cause: unknown) {
    super('ErrorParsingResponse', 'Error parsing response.', cause)
  }
}

export class AccountNotFound extends CustomError {
  constructor() {
    super('AccountNotFound', 'Account not found')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class InvalidAuthenticationTokenError extends CustomError {
  constructor() {
    super('InvalidAuthenticationTokenError', 'Authentication token is invalid.')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export abstract class HttpError extends CustomError {
  readonly method: string
  readonly url: string
  readonly timeoutMs: number | null
  protected constructor({
    name,
    message,
    method,
    url,
    timeoutMs
  }: {
    name: string
    message: string
    method: HttpMethod
    url: string
    timeoutMs?: number
  }) {
    super(name, message)
    this.method = method
    this.url = url
    this.timeoutMs = timeoutMs ?? null
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ConnectionError extends HttpError {
  constructor({
    name = 'ConnectionError',
    message = 'A network error occurred.',
    method,
    url,
    timeoutMs
  }: {
    name?: string
    message?: string
    method: HttpMethod
    url: string
    timeoutMs?: number
  }) {
    super({
      name,
      message,
      method,
      url,
      timeoutMs
    })
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ConnectionTimeoutError extends ConnectionError {
  constructor({
    method,
    url,
    timeoutMs
  }: {
    method: HttpMethod
    url: string
    timeoutMs?: number
  }) {
    super({
      name: 'ConnectionTimeoutError',
      message: 'The request timed out.',
      method,
      url,
      timeoutMs
    })
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ConnectionAbortedError extends ConnectionError {
  constructor({
    method,
    url,
    timeoutMs
  }: {
    method: HttpMethod
    url: string
    timeoutMs?: number
  }) {
    super({
      name: 'ConnectionAbortedError',
      message: 'The request was canceled.',
      method,
      url,
      timeoutMs
    })
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class HttpResponseError extends HttpError {
  readonly status: number
  constructor({
    name = 'HttpResponseError',
    message,
    method,
    url,
    timeoutMs,
    status
  }: {
    name?: string
    message?: string
    method: HttpMethod
    url: string
    timeoutMs?: number
    status: number
  }) {
    super({
      name,
      message: message || 'Request failed with HTTP status ' + status,
      method,
      url,
      timeoutMs
    })
    this.status = status

    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class HttpRateLimitError extends HttpResponseError {
  /**
   * Number of seconds to wait before retrying the request.
   */
  readonly retryAfter: number
  constructor({
    method,
    url,
    timeoutMs,
    retryAfter
  }: {
    method: HttpMethod
    url: string
    timeoutMs?: number
    retryAfter: number
  }) {
    super({
      name: 'HttpRateLimitError',
      message: `Rate limit exceeded.`,
      method,
      url,
      timeoutMs,
      status: 429
    })
    this.retryAfter = retryAfter
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class AuthenticationAborted extends CustomError {
  constructor() {
    super('AuthenticationAborted', 'User did not sign in or create an account.')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class NotFoundError extends CustomError {
  constructor(message: string) {
    super('NotFoundError', message)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
