import $ from 'jquery'
import { SignupParams } from '@/analytics/userProfile'

/**
 * Server side validation error for an input field.
 * Used in an Avetti AJAX API response.
 */
export interface AvettiFieldError {
  text: string
  place: string
  type: 'errors'
}

/**
 * Avetti AJAX API response container, which contains data about the result,
 * including field and global errors, and success/failure status.
 * @typeparam R The type of the result contained in the response object.
 */
export interface AvettiApiResponse<R> {
  fieldErrors: AvettiFieldError[]
  fieldMessages: string[]
  globalErrors: string[]
  globalMessages: string[]
  /** The result contained in the response object. */
  result: R
  success: boolean
}

/**
 * Avetti AJAX API response container for a request processed by the server successfully.
 * @typeparam R The type of the result contained in the response object.
 */
export interface AvettiApiResponseSuccess<R> extends AvettiApiResponse<R> {
  success: true
}

/**
 * Avetti AJAX API response container for a request that encountered a server-side error.
 * @typeparam R The type of the result contained in the response object.
 */
export interface AvettiApiResponseFailure<R> extends AvettiApiResponse<R> {
  success: false
}

/**
 * Avetti AJAX API response container for both successful and failed generic types.
 * @typeparam S The type of the result contained in a successful response object.
 * @typeparam F The type of the result contained in a failed response object.
 */
export type AvettiApiResponseSuccessFailure<S, F> =
  | AvettiApiResponseSuccess<S>
  | AvettiApiResponseFailure<F>

/**
 * Shared fields between anonymous and authenticated Avetti sessions.
 */
interface BaseSessionInfoResult {
  loggedIn: boolean
}

/**
 * API response for an unauthenticated Avetti user.
 * The session may have never been signed in, or was signed out explicitly or via timeout.
 */
export interface AnonymousSessionInfoResult extends BaseSessionInfoResult {
  loggedIn: false
}

/**
 * API response for the currently authenticated Avetti user.
 */
export interface AuthenticatedSessionInfoResult extends BaseSessionInfoResult {
  loggedIn: true
  /** The JSON Web Token (JWT) used to authenticate API requests. */
  token: string
  email: string
  firstName: string
  lastName: string
}

/**
 * API response with info about the current Avetti session.
 */
export type SessionInfoResult =
  | AnonymousSessionInfoResult
  | AuthenticatedSessionInfoResult

const requestAvetti = <T, F>(
  endpointPage: string,
  ajaxOptions: { [key: string]: unknown }
): JQuery.jqXHR<AvettiApiResponseSuccessFailure<T, F>> => {
  return $.ajax({
    url: '/cards/' + endpointPage,
    timeout: 15 * 1000,
    ...ajaxOptions
  })
}

/**
 * Parse email to simple convention format
 */
const normalizedEmail = (email: string) => email.toLowerCase()

/**
 * Make a direct API call to the sign in API.
 * This may return an error if the user is already signed in.
 * This isn't exposed directly and is instead wrapped with fallback behavior in `signIn`.
 */
const requestSignIn = (
  email: string,
  password: string
): JQuery.jqXHR<
  AvettiApiResponseSuccessFailure<
    AuthenticatedSessionInfoResult,
    AnonymousSessionInfoResult
  >
> => {
  return requestAvetti('register.html', {
    type: 'POST',
    data: {
      ajax: true,
      vid: 20090115001,
      mode: 'Log In',
      'loginViewDTO.doSubmit': 'Log In',
      'loginViewDTO.login': normalizedEmail(email),
      'loginViewDTO.password': password
    }
  })
}

/**
 * Sends an API request to get session information.
 * @returns A jQuery jqXHR object that resolves to a successful `AvettiApiResponse` with a `SessionInfoResult`.
 */
export const requestSessionInfo: () => JQuery.jqXHR<
  AvettiApiResponseSuccess<SessionInfoResult>
> = () =>
  requestAvetti('register.html', {
    dataType: 'json',
    data: {
      vid: 20090115001,
      ajax: true
    }
  }) as JQuery.jqXHR<AvettiApiResponseSuccess<SessionInfoResult>>

/**
 * Sends an API request to sign the user out.
 * @returns A jQuery jqXHR object that resolves to an `AvettiApiResponse` with an `AnonymousSessionInfoResult`.
 */
export const signOut = (): JQuery.jqXHR<
  AvettiApiResponseSuccess<AnonymousSessionInfoResult>
> => {
  return requestAvetti('signout.html', {
    dataType: 'json',
    cache: false,
    data: {
      vid: 20090115001,
      ajax: true
    }
  }) as JQuery.jqXHR<AvettiApiResponseSuccess<AnonymousSessionInfoResult>>
}

/**
 * get an existing Avetti account that is already signed in using its email.
 *
 * If there are session conflicts, Avetti will reject the initial sign in request.
 * However, this will wrap the raw request, and automatically sign out before retrying signing in if necessary.
 */
export const getSessionInfo = async (
  email: string,
  password: string
): Promise<
  AvettiApiResponseSuccessFailure<
    AuthenticatedSessionInfoResult,
    AnonymousSessionInfoResult
  >
> => {
  const sessionInfo = await requestSessionInfo()
  if (
    typeof sessionInfo.result === 'object' &&
    'email' in sessionInfo.result &&
    sessionInfo.result.email === email
  ) {
    // Return session info for the current user whose email matches the request email
    return sessionInfo as AvettiApiResponseSuccess<AuthenticatedSessionInfoResult>
  } else {
    // The current user's email does not match the request email.
    // Sign out, then retry signing in.
    await signOut()
    return requestSignIn(email, password)
  }
}

/**
 * Sign in to an existing Avetti account using the given email and password.
 */
export const signIn = async (
  email: string,
  password: string
): Promise<
  AvettiApiResponseSuccessFailure<
    AuthenticatedSessionInfoResult,
    AnonymousSessionInfoResult
  >
> => {
  if (!email) {
    throw new Error('Email is required')
  }
  if (!password) {
    throw new Error('Password is required')
  }

  const signInResponse = await requestSignIn(email, password)

  if (
    signInResponse.globalMessages &&
    signInResponse.globalMessages[0] ===
      'User is already logged in,do signout first'
  ) {
    return await getSessionInfo(email, password)
  } else {
    return signInResponse
  }
}

/**
 * Sends a POST request to the server to create a new account.
 *
 * @param signupParams - Additional parameters to include in the request body.
 * @returns A Promise that resolves with the server's response.
 */
export const createAccountRequest = ({
  signupParams,
  email,
  password,
  firstName,
  lastName
}: {
  signupParams: SignupParams
  email: string
  password: string
  firstName: string
  lastName: string
}): JQuery.jqXHR<AvettiApiResponseSuccess<AuthenticatedSessionInfoResult>> =>
  requestAvetti('register.html', {
    type: 'POST',
    data: {
      ...signupParams,
      ajax: true,
      vid: 20090115001,
      doSubmit: 'register',
      'customerDTO.loginname': email,
      confirmLogin: email,
      'customerDTO.loginpassword': password,
      confirmPassword: password,
      'customerDTO.firstName': firstName,
      'customerDTO.lastName': lastName,
      agree: 'yes'
    }
  }) as JQuery.jqXHR<AvettiApiResponseSuccess<AuthenticatedSessionInfoResult>>

/**
 * Creates a new account.
 *
 * @param signupParams - Additional parameters to include in the request body.
 * @returns A Promise that resolves with the server's response.
 * @throws An error if any required parameter is missing.
 */
export const createAccount = async ({
  signupParams,
  email,
  firstName,
  lastName,
  password
}: {
  signupParams: SignupParams
  email: string
  firstName: string
  lastName: string
  password: string
}): Promise<AvettiApiResponseSuccess<AuthenticatedSessionInfoResult>> => {
  return new Promise((resolve, reject) => {
    if (!email) {
      reject(new Error('Email is required'))
    }
    if (!password) {
      reject(new Error('Password is required'))
    }
    if (!firstName) {
      reject(new Error('First name is required'))
    }
    if (!lastName) {
      reject(new Error('Last name is required'))
    }

    return createAccountRequest({
      signupParams,
      email,
      firstName,
      lastName,
      password
    }).then(resolve, (jqXHR, textStatus, errorThrown) => {
      if (jqXHR.status === 409) {
        // Server returned HTTP 409 Conflict because a user is already signed in.
        // Sign out and then retry creating the account.

        signOut().then(() =>
          createAccount({
            signupParams,
            email,
            firstName,
            lastName,
            password
          }).then(resolve, reject)
        )
      } else {
        const error = new Error('Create Account request error')
        error.cause = {
          jqXHR,
          textStatus,
          errorThrown
        }
        reject(error)
      }
    })
  })
}

/**
 * Defining how forgotPassword method will respond
 */
interface ForgotPasswordResponse {
  success: boolean
}

/**
 * Sends a request for a new password for the given email address.
 */
export const forgotPassword = async (
  email: string
): Promise<ForgotPasswordResponse> => {
  return new Promise((resolve, reject) => {
    requestAvetti('forgot_password.html', {
      type: 'POST',
      data: {
        mode: 'sendEmail',
        login: normalizedEmail(email) // Normalize the email address before sending the request
      }
    }).then(
      (response) => {
        const htmlResponse = $(response)
        const errors = htmlResponse.find('.field-errors')

        resolve({
          success: !errors.length
        })
      },
      (jqXHR, textStatus, errorThrown) => {
        const error = new Error('Forgot password request error')
        error.cause = {
          jqXHR,
          textStatus,
          errorThrown
        }
        reject(error)
      }
    )
  })
}

/**
 * Defining how change password method will respond
 */
interface ChangePasswordResponse {
  success: boolean
  successMessages?: string
}

const requestChangePassword = (
  currentPassword: string,
  newPassword: string
): JQuery.jqXHR<string> =>
  requestAvetti('myaccount.html?mode=changepassword&vid=20090115001', {
    type: 'POST',
    authenticate: true,
    data: {
      _targetchangepassword:
        'myaccount.html?mode=orderstatus&vid=20090115001&cs=3',
      oldpassword: currentPassword,
      newpassword: newPassword,
      confirmpass: newPassword,
      submit: 'update'
    }
  }) as unknown as JQuery.jqXHR<string> // request returns html

/**
 * Changes the password for the current user.
 *
 * @param currentPassword The current password for the user.
 * @param newPassword The new password to set for the user.
 * @returns A Promise that resolves with the response from the server.
 */
export const changePassword = (
  currentPassword: string,
  newPassword: string
): Promise<ChangePasswordResponse> => {
  return new Promise((resolve, reject) => {
    requestChangePassword(currentPassword, newPassword).then(
      (html) => {
        if (typeof html === 'string') {
          const response = $(html)
          const errorMessages = response.find('.error')
          const successMessages = response.find('.user-info-changed')
          if (errorMessages.length > 0) {
            const error = new Error('There was a problem changing the password')
            error.cause = {
              errorMessages: errorMessages
                .map(function () {
                  return $(this).text()
                })
                .toArray()
            }
            reject(error)
          } else if (successMessages.length === 0) {
            reject(new Error('There was a problem changing the password'))
          } else {
            resolve({ success: true })
          }
        }
      },
      () => {
        resolve({
          success: false
        })
      }
    )
  })
}
