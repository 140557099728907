import { enqueue } from '@/utils/queues/pc-queues'
import { type QueueName } from '@/utils/queues/types'

const measureTierLoad = (tier: number): void => {
  const measureName = `Tier ${tier} Load`
  if (tier == 1) {
    window.performance?.measure(measureName)
  } else {
    // Measure time since last tier finished to determine the this tier's incremental load time.
    // This could be nearly zero due to prefetching, preloading, or caching.
    window.performance?.measure(measureName, `Tier ${tier - 1} end`)
  }
}

/**
 * Utility to handle boilerplate for tiered page loading.
 * We load and execute pages in tiers to prioritize rendering, leading to improved perceived loading performance.
 *
 * After this is called to initialize the "current" tier, an `import()` for the next tier should be called.
 * Because we rely on [Webpack magic comments](https://webpack.js.org/api/module-methods/#magic-comments) to control
 * chunk naming and loading behavior for each `import()`, that step has to be hard-coded for each page.
 *
 * @param tier Tier number. Lower numbers are executed first.
 *
 *   - 0 (implicit): Inlined code that runs before anything that's bundled by Webpack.
 *     This basically only contains a light version of `pc.enqueue`, which allows async method calls to be queued
 *     until the relevant code is lazily loaded.
 *   - 1: Wires up essentials (like error tracking instrumentation), and above the fold rendering.
 *     The goal is to render what the user can initially see as quickly as possible, and delay
 *     enabling interactivity or rendering other content until later.
 *   - 2: Above the fold interactivity. Content the user can immediately click on should be interactive as quickly
 *     as possible. This includes any menus or buttons that are visible when the page loads.
 *     Be sure to consider what content is visible on various viewport sizes (e.g. mobile vs desktop).
 *   - 3: Everything else. Once the page has loaded and above the fold content is visible and interactive,
 *     we can trigger lazy loading for other content or interactivity below the fold, load analytics / tracking,
 *     and everything else. Nothing in this tier has any visible effect on the initially visible content.
 * @param tierInits Async initialization functions to run in parallel for this tier. These typically include 1 or more
 * shared initialization functions (e.g. for common or shared code), and a page-specific initialization function if
 * necessary. Some pages (e.g. static ones or non-interactive ones) won't need page-specific initialization.
 */
export async function initPageTier(
  tier: 1 | 2 | 3,
  tierInits: Array<(() => Promise<void>) | (() => void)>
): Promise<void> {
  measureTierLoad(tier)
  window.performance?.mark(`Tier ${tier} start`)
  enqueue(`pageInit.onTier${tier}Start` as QueueName)
  // Initialize all code for this tier in parallel.
  // A tier can optionally wait for work to be completed before allowing the tier to be considered complete.
  // For example, if a tier loaded some content, it could avoid resolving its init Promise until all content was
  // loaded, which would delay the next tier's loading. This capability should be used with extreme caution because
  // it could significantly delay complete initialization of the page, especially on a bad connection.
  await Promise.all(tierInits.map((init) => init()))
  enqueue(`pageInit.onTier${tier}End` as QueueName)
  window.performance?.mark(`Tier ${tier} end`)
}
