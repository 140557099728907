import $ from 'jquery'
import { Spinner } from 'spin.js'
import _ from 'underscore'
import template from './loading.html'
import 'bootstrap/js/modal'

let windowUnloaded = false
window.addEventListener('beforeunload', () => {
  windowUnloaded = true
})

window.addEventListener('pageshow', () => {
  // Clean up state when a page is restored or returned to from the `bfcache`.
  // This won't do anything on the initial page load.
  windowUnloaded = false
  if (lastMessage !== null) {
    // Hide loading if it was displayed immediately before navigating away from the page.
    // This is a workaround for scenarios where a loading spinner is opened immediately before a user
    // leaves a page, but is then restored when navigating back due to `bfcache`.
    // This assumes that in most cases, it's better to allow the user to interact with the restored page again
    // instead of being stuck with a spinner and no way to click on anything.
    // However, if any code purposely prevents interaction due to prevent errors, those scenarios should be handled
    // independently (e.g., with a dedicated listener, or caching rules) to avoid illegal state.
    hide()
  }
})

let $template = null
let initialized = false
let showDelay = null
let lastMessage = null

const enforceFocus = $.fn.modal.Constructor.prototype.enforceFocus

const initialize = () => {
  if (!initialized) {
    initialized = true
    $template = $(template())
    new Spinner().spin($template.find('#loading-spinner').get(0))
    $template.appendTo('body')
  }
}

// This avoids quick flashes of loading when for stuff that loads super quickly or
// stuff that's already loaded. Resources might already be loaded with the pattern of
// show loading, load, hide loading; in that case, this won't show loading for already
// loaded resources.
const showDelayed = (message = '') => {
  showDelay = _.delay(() => {
    // Since we defer, hide might be called before show is called
    if (showDelay === null) {
      return
    }
    show(message)
  }, 50) // Display loading only if resources don't load within a few ms
}

// CAUTION: Message is assumed be to safe HTML. Don't use this with user supplied content.
const show = (message = '', modalOptions = {}) => {
  // Allows loading to return the message it's currently displaying so we could hide the current
  // loading, then restore it with the previous message.
  lastMessage = message

  initialize()

  // Disable enforceFocus due to an infinite recursion caused with a focus battle between
  // Bootstrap modal and jQuery UI Dialog. Restore this once the modal is closed.
  $.fn.modal.Constructor.prototype.enforceFocus = () => {}

  const $message = $template.find('p')
  if (message) {
    // Convert newlines to HTML line breaks
    $message.html(message.replace(/\n/g, '<br>'))
    $message.show()
  } else {
    // We don't want the element to affect spacing
    $message.hide()
  }

  $template.find('.modal-content').css(
    message
      ? { width: 300 }
      : // Perfect square with loading spinner centered
        { width: 88 }
  )

  $template.modal(
    _.extend({ keyboard: false, backdrop: 'static' }, modalOptions)
  )
}

const hide = (hideOptions = {}) => {
  // Don't hide loading if the user is on their way off the page
  if (windowUnloaded && !hideOptions.alwaysHide) {
    return
  }

  if (showDelay !== null) {
    clearTimeout(showDelay)
    showDelay = null
  }
  $.fn.modal.Constructor.prototype.enforceFocus = enforceFocus
  if ($template != null) {
    $template.modal('hide')
  }
  lastMessage = null
}

const displayedMessage = () => lastMessage

export default { showDelayed, show, hide, displayedMessage }
