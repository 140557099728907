import { parseJwt, fetchMercury, MercuryTokens } from '@/api/mercury/fetch'
import { CreateAccountParameters } from '@/services/account'
import {
  DuplicatedAccount,
  InvalidAuthenticationTokenError,
  InvalidCredentials
} from '@/api/error'
import { FetchError } from 'ofetch'
import authChannel from '@/auth/channel'

/**
 * Represents the API response for user login.
 */
export interface AuthenticationResponse {
  accessToken: string
  /** The lifetime in seconds of the access token. The "exp" in the access token payload can be used instead. */
  expiresIn: number
  refreshToken: string
  tokenType: string
  firstName: string
  lastName: string
}

/**
 * Creates a new user.
 */
export const createUser = async ({
  email,
  password,
  firstName,
  lastName,
  signupParams,
  referrer,
  referrerOther
}: CreateAccountParameters): Promise<AuthenticationResponse> => {
  try {
    // .json needed to set session cookie for server-side rendered checkout
    const response = await fetchMercury<AuthenticationResponse>({
      method: 'POST',
      path: '/mercury/api/users.json',
      data: {
        user: { email, password, firstName, lastName },
        context: {
          signupParams,
          referrer,
          referrerOther
        }
      }
    })

    const { accessToken, refreshToken } = response
    MercuryTokens.create(accessToken, refreshToken)

    return response
  } catch (error) {
    if (
      error instanceof FetchError &&
      error.data?.errors?.email?.[0] === 'has already been taken'
    ) {
      throw new DuplicatedAccount()
    } else {
      throw new Error('There was an error creating your account.')
    }
  }
}

/**
 * Authenticates a user and retrieves an access token.
 */
export const signIn = async (
  email: string,
  password: string
): Promise<AuthenticationResponse> => {
  try {
    // .json needed to set session cookie for server-side rendered checkout
    const response = await fetchMercury<AuthenticationResponse>({
      method: 'POST',
      path: '/mercury/oauth/token.json',
      data: { username: email, password, grantType: 'password' }
    })

    const { accessToken, refreshToken } = response
    MercuryTokens.create(accessToken, refreshToken)

    return response
  } catch (error) {
    if (
      error instanceof FetchError &&
      error.data?.error === 'invalid username or password'
    ) {
      throw new InvalidCredentials()
    } else {
      throw new Error('There was an error signing in')
    }
  }
}

/**
 * Signs out the user and clears the access token.
 */
export const signOut = async (): Promise<void> => {
  await fetchMercury<void>({
    method: 'DELETE',
    path: '/mercury/api/users/sign_out'
  })
  MercuryTokens.remove()
}

/**
 * Sends a recover password email to the user.
 */
export const requestPasswordReset = async (email: string): Promise<void> => {
  await fetchMercury<AuthenticationResponse>({
    method: 'POST',
    path: '/mercury/api/password/recover',
    data: {
      spreeUser: {
        email
      }
    }
  })
}

/**
 * Sends a new password and token to update the user's password.
 */
export const resetPassword = async (
  password: string,
  resetPasswordToken: string
): Promise<void> => {
  await fetchMercury<AuthenticationResponse>({
    method: 'PUT',
    path: '/mercury/api/password/change',
    data: {
      spreeUser: {
        password,
        resetPasswordToken
      }
    }
  })
}

/**
 * Updates the first name and last name of a user.
 */
const updateNameRequest = async (
  firstName: string,
  lastName: string,
  accessToken: string
): Promise<void> => {
  const userId = parseJwt(accessToken).sub
  await fetchMercury<void>({
    method: 'PATCH',
    path: `/mercury/api/users/${userId}`,
    accessToken,
    data: {
      user: {
        firstName,
        lastName
      }
    }
  })
}

export const updateName = async (
  firstName: string,
  lastName: string
): Promise<void> => {
  return withAccessToken<void>((accessToken) => {
    return updateNameRequest(firstName, lastName, accessToken)
  })
}

const showSignInModal = () => authChannel.request('signin')

/**
 * Executes a callback function with an access token.
 * If the token is invalid, it tries to renew it and then executes the callback.
 * @template T - The type of the callback function's response.
 * @param callback - A function that receives the access token as a parameter and returns a Promise.
 * @returns A Promise that resolves with the result of the callback.
 * @throws {Error} If the access token is not available or invalid.
 */
export const withAccessToken = async <T>(
  callback: (accessToken: string) => Promise<T>
): Promise<T> => {
  try {
    const mercuryTokens = await MercuryTokens.loadWithUnexpiredAccessToken()
    if (mercuryTokens) {
      return await callback(mercuryTokens.accessToken)
    } else {
      throw new InvalidAuthenticationTokenError()
    }
  } catch (error) {
    if (error instanceof InvalidAuthenticationTokenError) {
      MercuryTokens.remove()
      await showSignInModal()
      return withAccessToken(callback)
    } else {
      throw error
    }
  }
}
