export type SigninUiOptions = {
  /**
   * An optional message can be displayed to the user to explain why
   * signin is necessary.
   */
  signinMessage: string | null
  /**
   * set to `true` to disable account creation.
   * Useful to simplify the user experience if we know a request
   * requires an existing account.
   */
  signinOnly: boolean
  /**
   * If `true`, email is readonly in signin as long as an email is defined.
   */
  forceUsername: boolean
  /**
   * Set to `false` to make it impossible to close the signin modal,
   * e.g. with the escape key or by clicking the close button (which will be removed).
   */
  closable: boolean
  /**
   * Set to prepopulate the email field.
   *
   * Consider also setting `forceUsername` to `true` if only one specific user
   * should be allowed to authenticate.
   */
  email: string | null
  /**
   * Set to `false` to avoid showing loading spinner.
   * This is useful when displaying signin inline since there's an existing
   * loading image.
   */
  showLoading: boolean
}
// Set defaults
let currentOptions: SigninUiOptions = {
  signinMessage: null,
  signinOnly: false,
  forceUsername: false,
  closable: true,
  email: null,
  showLoading: true
}

/**
 * Calls a `callback` function, which can temporarily mutate a copy of
 * shared `signInUiOptions`. Once these options are mutated, lower level code can call
 * `getSigninUiOptions()` to grab the mutated options.
 *
 * After `callback` is executed, the original shared options will be restored.
 *
 * @param callback Function that takes a mutatable copy of the current shared options
 * and will call `getSigninUiOptions()` before the function has completed.
 *
 * CAUTION:
 *   `callback` must call `getSigninUiOptions()` within synchronous code
 *   i.e. before any network requests are initialted,
 *   otherwise we will have restored the old options already.
 * @returns Result of the callback
 */
export function withSigninUiOptions<T>(
  callback: (signInOptions: SigninUiOptions) => T
): T {
  const oldOptions = currentOptions
  // Set shared state temporarily with a new object
  currentOptions = { ...currentOptions }
  // callback can mutate the shared state of currentOptions
  const result = callback(currentOptions)

  // Restore the previous options
  currentOptions = oldOptions
  return result
}

export const getSigninUiOptions = (): SigninUiOptions => {
  // Return a defensive copy just in case the caller does some naughty mutation
  return { ...currentOptions }
}
