import $ from 'jquery'
import getUserEmail from '@/analytics/getUserEmail'
import cookies from '@/utils/cookies'
import marketo from '@/analytics/marketo'
import { urlParams } from '@/utils/url'
import _ from 'underscore'
import storage from '@/utils/storage'
import { upsertAccount } from '@/api/mondrian/account'
import { analytics } from '@/analytics/main'

// Previously known as pc.track
// eslint-disable-next-line no-var -- FIXME
var userProfile = {
  // Associate the passed username with all tracking services and write a rememberedEmail
  // cookie so that login and email fields can be prepopulated.
  // If name (optional) is present, it will be sent to services that support it and we
  // assume the user has a full Paper Culture account. If there's no name, we assume
  // we're collecting the name via a lead gen channel and that the user doesn't have a full account.
  // Wait for the promise returned to ensure the Marketo lead is fully merged before logging lead events
  // that need to be triggered with the lead's email to avoid flaky Marketo behavior.
  // userData is an optional object with extended session data.

  // Maintain this prototype for external uses like GTM
  setUserData(email, name, isExistingMarketoAccount, userData) {
    if (!userData) {
      // name is only set when there's full userData from the session, so this case is only
      // for email-only scenarios.
      userData = {
        email
      }
    } else if (!userData.email) {
      // If userData exists without userData.email
      userData.email = email
    }

    userData.name = userData.name || name

    const options = {
      isExistingMarketoAccount,
      // If name is populated, assume this call is made when an account is created
      // or when the user signs in. This is a little brittle since we may collect
      // a name from leads without full accounts too, so it's more explicit in setUserDataV2.
      isFullCommerceAccount: !!userData.name
    }

    return userProfile.setUserDataV2(userData, options)
  },

  setUserDataV2(userData, options) {
    // userData can contain lead attributes to upsert such as firstName, lastName, weddingDate,
    // but it may also optionally have an array of sample requests under sampleRequests,
    // which will always insert new sample requests (and not update them).

    options = options || {}
    options.isExistingMarketoAccount = options.isExistingMarketoAccount || false
    options.isFullCommerceAccount = options.isFullCommerceAccount || false

    // Clicky
    window.clicky_custom = window.clicky_custom || {}
    // eslint-disable-next-line no-undef -- FIXME
    if (!clicky_custom.visitor) {
      // eslint-disable-next-line no-undef -- FIXME
      clicky_custom.visitor = {}
    }
    // eslint-disable-next-line no-undef -- FIXME
    clicky_custom.visitor.username = userData.email
    if (userData.name) {
      // eslint-disable-next-line no-undef -- FIXME
      clicky_custom.visitor.name = userData.name
    }

    // Woopra
    const woopraData = {
      email: userData.email
    }
    if (userData.name) {
      woopraData.name = userData.name
    }
    analytics.woopraIdentify(woopraData)

    // Sentry
    if (typeof Sentry === 'object') {
      // If you go to a page and login (and your email hasn't been stored in local storage), this will handle
      // updating the current Sentry configuration to use the currently logged in user.
      // _.pick excludes token or additional info we might not care about in Sentry
      // eslint-disable-next-line no-undef -- FIXME
      Sentry.configureScope(function (scope) {
        scope.setUser(_.pick(userData, 'email', 'name'))
      })
    }

    // Set cookies to pre-populate signin
    if (options.isFullCommerceAccount) {
      // The presence of this cookie tells the user model to present SigninView instead of CreateAccountView
      cookies.setItem('rememberedEmail', userData.email, Infinity, '/')
    } else {
      // Set ecpEmail only so when we get to signin, we can detect that this user didn't have a account
      cookies.setItem('ecpEmail', userData.email, Infinity, '/')
    }

    if (options.isExistingMarketoAccount) {
      // We got the email and `mkt_tok` from query string parameters from a Marketo email, so we can assume
      // the user exists in Marketo already and has their `mkt_tok` associated with their email / lead.
      // There's no need to create an account in Salesforce again or have the backend associate the lead.
      return $.Deferred().resolve().promise()
    } else {
      // New lead gen only account creation to add the user to Marketo and Salesforce and associate the
      // Munchkin tracking cookie with the Marketo lead
      return userProfile.upsertAccountWithSignupParams(userData)
    }
  },

  getSignupParams() {
    return storage.getItem('signupParams') || {}
  },

  // Look for lead-gen campaign tracking params in the URL and log them to local storage
  // so we can record the params in Salesforce when we collect the user's email address to measure
  // the effectiveness of different campaign cohorts.
  logSignupParams() {
    const signupParams = {}
    $.each(urlParams(), function (name, value) {
      if (/^(pc|utm)_[a-z0-9]+/.test(name)) {
        signupParams[name] = value
      }
    })

    // Log referer only if it was external. Current logic assumes there are no subdomains.
    // Must check that regexp matches from the beginning of the referrer to filter out redirect URLs.
    const referrerIsLocal = new RegExp('^https?://' + location.host).test(
      document.referrer
    )
    if (document.referrer && !referrerIsLocal) {
      // Internally, the HTTP header misspelling is used
      signupParams.referer = document.referrer
    }

    // Log only if we don't already have an email address logged for the user
    if (!$.isEmptyObject(signupParams) && !getUserEmail()) {
      const oldParams = storage.getItem('signupParams')
      // Never overwrite existing params so that logged data is based on the first visit (that we have data for)
      if (!oldParams) {
        storage.setItem('signupParams', signupParams)
      }
    }
  },

  /**
   * Attempts to create an account in Salesforce with all the signup params previously logged.
   *
   * We'll call this when adding people to the database whether they're just a lead, or if they're creating
   * a full account. For new leads, the account upsert backend will store the Munchkin cookies in the Salesforce
   * lead to defer association until the Salesforce lead has been synced into Marketo. This avoids duplicate
   * lead creation, which occurs if a lead already exists with the same email in Marketo, the first time a
   * lead is synced in from Salesforce. If a lead already exists in Marketo and has been synced back to Salesforce,
   * the backend will use the Marketo REST API to immediately associate the lead's cookie with their Marketo lead.
   *
   * @param userData Context object with the user's `email`, and other optional fields including
   * `firstName`, `lastName`, and `weddingDate`.
   * @param forceAuthentication Set to true to authenticate the request (force login) to allow updating attributes
   * such as first name and last name, which are protected for users with a full commerce account.
   * By default,requests are made without authentication, even if the user is signed in.
   */
  upsertAccountWithSignupParams(userData, forceAuthentication) {
    if (!userData.email) {
      throw new Error(
        'userData.email must exist to create an account with signup params'
      )
    }

    // Get stored first-touch-attribution signup params and map their query string parameter names
    // to the account upsert API names
    const urlParamsToRequestParams = {
      pc_c1: 'signupCustom1',
      pc_c2: 'signupCustom2',
      pc_c3: 'signupCustom3',
      pc_c4: 'signupCustom4',
      pc_c5: 'signupCustom5',
      pc_c6: 'signupCustom6',
      pc_c7: 'signupCustom7',
      pc_c8: 'signupCustom8',
      pc_c9: 'signupCustom9',
      pc_cdesc: 'signupCreativeDescription',
      pc_class: 'signupClass',
      pc_dest: 'signupDestination',
      pc_seg: 'signupSegment',
      utm_campaign: 'signupUtmCampaign',
      utm_content: 'signupUtmContent',
      utm_medium: 'signupUtmMedium',
      utm_source: 'signupUtmSource',
      utm_term: 'signupUtmTerm',
      referer: 'signupReferer'
    }
    const signupParams = _.reduce(
      this.getSignupParams(),
      function (mappedParams, value, key) {
        const newKey = urlParamsToRequestParams[key]
        if (newKey) {
          mappedParams[newKey] = value
        }
        return mappedParams
      },
      {
        // Unlike other signup params, we capture this when the upsert is made.
        // There's little benefit to storing this on first touch attribution as this won't typically change.
        // If it does, only the browser version, or OS version would change, and we don't care about that
        // difference.
        signupUserAgent: navigator.userAgent,
        marketoTrackingCookie: cookies.getItem(marketo.TRACKING_COOKIE)
      }
    )

    const { email, ...userDataWithoutEmail } = userData
    // If email is present in accountUpsert, it's considered to be an email address change
    const accountUpsert = $.extend(signupParams, userDataWithoutEmail)

    // Allow callers to ensure that tracking is done.
    return upsertAccount(email, accountUpsert, forceAuthentication)
  }
}

export default userProfile
