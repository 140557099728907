/*\
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  https://developer.mozilla.org/en-US/docs/DOM/document.cookie
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * cookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * cookies.getItem(name)
|*|  * cookies.removeItem(name[, path])
|*|  * cookies.hasItem(name)
|*|  * cookies.keys()
|*|
\*/
export default {
  getItem(sKey) {
    return (
      unescape(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;\\s*)' +
              // eslint-disable-next-line no-useless-escape -- FIXME
              escape(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
              '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*)|.*'
          ),
          '$1'
        )
      ) || null
    )
  },
  setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    // eslint-disable-next-line no-useless-escape -- FIXME
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false
    }
    let sExpires = ''
    if (vEnd) {
      switch (vEnd.constructor) {
        case String:
          sExpires = '; expires=' + vEnd
          break
        case Number:
          if (vEnd === Infinity) {
            // The maximum number of seconds elapsed since since 1 January 1970 00:00:00 UTC expressible by a
            // signed 32-bit integer (i.e.: 01111111111111111111111111111111, which is new Date(0x7fffffff * 1e3)).
            sExpires = '; expires=Tue, 19 Jan 2038 03:14:07 GMT'
            break
          }
          // vEnd = current date plus the number of seconds passed in
          vEnd = new Date(new Date().getTime() + vEnd * 1000)
        // fall through
        case Date:
          sExpires = '; expires=' + vEnd.toGMTString()
          break
      }
    }
    document.cookie =
      escape(sKey) +
      '=' +
      escape(sValue) +
      sExpires +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '') +
      (bSecure ? '; secure' : '')
    return true
  },
  removeItem(sKey, sPath) {
    if (!sKey || !this.hasItem(sKey)) {
      return false
    }
    document.cookie =
      escape(sKey) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (sPath ? '; path=' + sPath : '')
    return true
  },
  hasItem(sKey) {
    return new RegExp(
      // eslint-disable-next-line no-useless-escape -- FIXME
      '(?:^|;\\s*)' + escape(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\='
    ).test(document.cookie)
  },
  /* optional method: you can safely remove it! */
  keys() {
    const aKeys = document.cookie
      // eslint-disable-next-line no-useless-backreference, no-useless-escape -- FIXME
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      // eslint-disable-next-line no-useless-escape -- FIXME
      .split(/\s*(?:\=[^;]*)?;\s*/)
    for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = unescape(aKeys[nIdx])
    }
    return aKeys
  }
}
