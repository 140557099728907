import Radio from 'backbone.radio'
import $ from 'jquery'
import loading from '@/loading'

const channel = Radio.channel('auth')

// If this is populated, we're waiting for signin to load
let signinLoadingPromise = undefined

// If this is populated, we've attempted to load the signin app,
// and it's a promise that's resolved with the loaded app
let signinAppPromise = undefined

// Register any handlers that lazy load decoupled modules so they're available
// immediately once the channel is required.
channel.reply('signin', function (options) {
  const signinLoadingDeferred = $.Deferred()
  signinLoadingPromise = signinLoadingDeferred.promise()

  // We only care about this resolving once the app has loaded and don't care about
  // rejection (assuming it shouldn't happen)
  // eslint-disable-next-line no-var -- FIXME
  var signinAppDeferred = signinAppDeferred || $.Deferred()
  signinAppPromise = signinAppDeferred.promise()

  // See if there's currently a loading message shown so we can later restore it
  const displayedMessage = loading.displayedMessage()

  // Don't show loading if there's an existing loading message or if showLoading is false.
  const showLoading =
    displayedMessage === null && options?.showLoading !== false

  // If there's not already a loading indicator, show one while we lazily load the auth bundle
  if (showLoading) {
    loading.showDelayed('Loading secure signin')
  }

  import('@/auth/signin/app')
    .then(() => {
      // We're not loading signin anymore
      signinLoadingPromise = undefined

      // Only hide loading before signin opens if it was only for loading signin itself.
      // If loading was already open, we'll leave it open so that signin will see the previous
      // loading message, remember what it said, then restore it when signin is successful.
      if (showLoading) {
        loading.hide()
      }

      // TODO: Fix bug where .request returns `undefined` if the reply for `signin:show` has not been registered yet.
      // This happens when we get here before signin/app initializes.
      channel
        .request('signin:show', options)
        .done(signinLoadingDeferred.resolve)
        .fail(signinLoadingDeferred.reject)

      // Signals that the app has been loaded, initialized, and the initial signin:show has been requested
      signinAppDeferred.resolve()
    })
    .catch(signinLoadingDeferred.reject)

  return signinLoadingDeferred.promise()
})

// Returns a promise if there's a signin in progress otherwise false.
// Useful to prevent other stuff from happening if signin is happening or to queue
// stuff up until it's done (if a promise is returned).
channel.reply('signin:status', function () {
  if (
    (signinAppPromise != null ? signinAppPromise.state() : undefined) ===
    'resolved'
  ) {
    return channel.request('signin:promise')
  } else {
    // If signin isn't loaded, we return this promise that's populated while signin is loading.
    // If signin is in the process of loading, this will be a promise.
    // If signin isn't loaded, this will just be undefined.
    return signinLoadingPromise
  }
})

export default channel
