// Local storage wrapper to ensure browser compatibility. Allows saving objects directly with an optional expiration.
import pcLocalStorage from '@/pcLocalStorage'
import { track } from '@/analytics/trackAsync'
import 'jsurl' // Exposes window.JSURL

// eslint-disable-next-line no-var -- FIXME
var storage = {
  ERROR_CODE_UNSUPPORTED: 'UNSUPPORTED_FUNCTION',

  ERROR_CODE_STORAGE_FULL: 'QUOTA_EXCEEDED',

  _namespace: 'pc.',

  _isPcNamespaced(key) {
    return key.indexOf(storage._namespace) === 0
  },

  // True if localStorage is supported and is writeable (vs read-only in Safari private browsing)
  isSupported() {
    return !pcLocalStorage.fallback
  },

  isCookieFallback() {
    // TODO: Once we are HTTPS only, be smarter about the cookie fallback by checking for free
    // cookie space when we set a value based on the total resulting length of the cookie (4093 bytes max).
    // Don't cache this because cookie length will change as stuff is added.
    return !!pcLocalStorage.fallback && document.cookie.length < 4000
  },

  // If we're using a cookie fallback, we encode and decode JSON for compact cookie storage
  // with JSURL. Otherwise, we use standard JSON for localStorage persistence.
  _jsonLib() {
    // eslint-disable-next-line no-undef -- FIXME
    return storage.isCookieFallback() ? JSURL : JSON
  },

  // Add an item to local storage (if supported) and set an optional expiration if TTL is specified (in seconds).
  // Expired items will be flushed from the cache if there's an error setting a new item (presumably meaning that
  // we've exhausted all usable local storage space.
  // Warning: undefined is not a valid value and using it will produce unpredictable results in getItem.
  setItem(key, value, ttl) {
    const cookieFallback = storage.isCookieFallback()
    if (storage.isSupported() || cookieFallback) {
      const namespacedKey = storage._namespace + key

      // Use short keys to save space since local storage space is limited to 5MB (usually)
      value = {
        v: value,
        // Store a timestamp even if there's no expiration so we could potentially delete the oldest items (not implemented)
        t: new Date().getTime()
      }
      // We could potentially add support for an expiration date and set that as a timestamp under an e key
      if (typeof ttl === 'number') {
        value.ttl = ttl
      }
      const valueAndExpiration = storage._jsonLib().stringify(value)

      try {
        pcLocalStorage.setItem(namespacedKey, valueAndExpiration)
      } catch (e1) {
        track('Storage full on setItem: ' + e1, {
          category: 'Local storage',
          label: storage.isCookieFallback()
            ? 'Cookie store'
            : 'localStorage store'
        })

        // Try flushing expired cache items to free up space
        storage.flush()

        try {
          // Try setting the value again after old items are flushed
          pcLocalStorage.setItem(namespacedKey, valueAndExpiration)
        } catch (e2) {
          // Finally, give up
          track('Storage full after flush: ' + e2, {
            category: 'Local storage',
            label: storage.isCookieFallback()
              ? 'Cookie store'
              : 'localStorage store'
          })
          throw storage.ERROR_CODE_STORAGE_FULL
        }
      }
    } else {
      track('Unsupported', {
        category: 'Local storage',
        label: 'Attempting to write key: ' + key
      })
      throw storage.ERROR_CODE_UNSUPPORTED
    }
  },

  getItem(key) {
    const cookieFallback = storage.isCookieFallback()
    const supported = storage.isSupported()
    if (supported || cookieFallback) {
      const item = pcLocalStorage.getItem(storage._namespace + key)
      return item && storage._jsonLib().parse(item).v
    } else {
      track('Unsupported', {
        category: 'Local storage',
        label: 'Attempting to get item with key: ' + key
      })
      throw storage.ERROR_CODE_UNSUPPORTED
    }
  },

  removeItem(key) {
    const cookieFallback = storage.isCookieFallback()
    const supported = storage.isSupported()
    if (supported || cookieFallback) {
      pcLocalStorage.removeItem(storage._namespace + key)
    } else {
      throw storage.ERROR_CODE_UNSUPPORTED
    }
  },

  clear() {
    const cookieFallback = storage.isCookieFallback()
    const supported = storage.isSupported()
    if (supported || cookieFallback) {
      pcLocalStorage.clear()
    } else {
      throw storage.ERROR_CODE_UNSUPPORTED
    }
  },

  // Flush expired entries from local storage. This is useful if we're out of space.
  // Not using any jQuery here so it can be used dependency free if we want it to be standalone.
  flush() {
    const cookieFallback = storage.isCookieFallback()
    const supported = storage.isSupported()
    if (supported || cookieFallback) {
      const now = new Date().getTime()
      for (let i = 0; i < pcLocalStorage.length; i++) {
        const key = pcLocalStorage.key(i)
        const itemString = pcLocalStorage.getItem(key)
        if (!storage._isPcNamespaced(key)) {
          return
        }

        const item = storage._jsonLib().parse(itemString)
        const expirationTime = item.t + item.ttl * 1000
        if (item.ttl && expirationTime < now) {
          const namespacedKey = key.substring(storage._namespace.length)
          storage.removeItem(namespacedKey)
        }
      }
    } else {
      throw storage.ERROR_CODE_UNSUPPORTED
    }
  }
}

export default storage
