import { enqueue } from '@/utils/queues/pc-queues'
import { TrackOptions, TrackProperties } from '@/analytics/types'

/**
 * Enqueues an analytics event to be tracked.
 *
 * This wrapper around pc.enqueue limits use of the pc.enqueue global, and allows us to log analytics events
 * asynchronously before a handler may be registered. This allows analytics to be logged while the page is
 * loading, but before analytics libraries are loaded so that more important work can be prioritized.
 *
 * Compared to using pc.enqueue directly, this also provides some type information to the caller.
 */
export const track = (
  event: string,
  properties: TrackProperties,
  options?: TrackOptions,
  callback?: () => void
): Promise<void> => {
  return new Promise((resolve) => {
    enqueue('analytics.track', event, properties, options, () => {
      callback?.()
      resolve()
    })
  })
}
