// Relative paths prevent webpack resolve alias from remapping the imports,
// and causing `RangeError: Maximum call stack size exceeded` during build.
import 'spin.js/spin.css'
import {
  Spinner as SpinnerSource,
  SpinnerOptions
} from '../node_modules/spin.js'

export class Spinner extends SpinnerSource {
  constructor(opts?: SpinnerOptions) {
    super({
      // Override global default from 2e9 to prevent the spinner from appearing over menus and modals
      zIndex: 1,
      ...opts
    })
  }
}
