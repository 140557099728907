import { enqueue } from '@/utils/queues/pc-queues'
import {
  CreatedAccountProperties,
  OrderCompletedProperties,
  SampleKitRequestSubmittedProperties
} from '@/analytics/types'

type EventName =
  | 'addToCart'
  | 'accountCreated'
  | 'leadCreated'
  | 'emailSignedUpPopup'
  | '_trackSocial'
  | 'sampleKitRequestSubmitted'
  | 'checkout'
  | 'checkoutOption'
  | 'checkoutCompleted'
  | 'removeFromCart'

export type GtmProduct = {
  name: string
  /** Product code is used for the ID for GTM e-commerce events. */
  id: string
  price: number
  brand: string
  category: string
  variant: string
  quantity: number
}

type AddToCartProperties = {
  ecommerce: {
    currencyCode: 'USD'
    add: {
      products: GtmProduct[]
    }
  }
}

type SocialProperties = {
  socialNetwork: string
  socialActionTarget: string
}

type CheckoutProperties = {
  ecommerce: {
    checkout: {
      actionField: {
        step: number
      }
    }
  }
}

type CheckoutOptionProperties = {
  ecommerce: {
    checkout_option: {
      actionField: {
        step: number
        option: string
      }
    }
  }
}

type CheckoutCompletedProperties = OrderCompletedProperties & {
  googleTagParams: {
    ecomm_prodid: string[]
    ecomm_pagetype: string
    ecomm_totalvalue: number
    ecomm_quantity: number[]
    hasaccount: string
  }
  ecommerce: {
    purchase: {
      actionField: {
        id: string
        revenue: string
        tax: string
        shipping: string
        coupon?: string
      }
      products: GtmProduct[]
    }
  }
}

type RemoveFromCartProperties = {
  ecommerce: {
    remove: {
      products: GtmProduct[]
    }
  }
}

export function trackEvent(
  eventName: 'addToCart',
  addToCartProperties: AddToCartProperties
): Promise<void>
export function trackEvent(
  eventName: 'accountCreated',
  accountCreatedProperties: CreatedAccountProperties
): Promise<void>
export function trackEvent(eventName: 'leadCreated'): Promise<void>
export function trackEvent(eventName: 'emailSignedUpPopup'): Promise<void>
export function trackEvent(
  eventName: '_trackSocial',
  socialProperties: SocialProperties
): Promise<void>
export function trackEvent(
  eventName: 'checkout',
  checkoutProperties: CheckoutProperties
): Promise<void>
export function trackEvent(
  eventName: 'checkoutOption',
  checkoutOptionProperties: CheckoutOptionProperties
): Promise<void>
export function trackEvent(
  eventName: 'sampleKitRequestSubmitted',
  sampleKitRequestSubmittedParams: SampleKitRequestSubmittedProperties
): Promise<void>
export function trackEvent(
  eventName: 'checkoutCompleted',
  checkoutCompletedProperties: CheckoutCompletedProperties
): Promise<void>
export function trackEvent(
  eventName: 'removeFromCart',
  removeFromCartProperties: RemoveFromCartProperties
): Promise<void>
/**
 * Tracks an event with GTM and returns a promise that will resolve when the GTM tag has fired or after a timeout.
 *
 * @param eventName - The name of the event to track.
 * @param data - Optional data to include with the event.
 * @returns A promise that resolves after the event has been processed, or after a timeout.
 */
export function trackEvent(
  eventName: EventName,
  data: { [key: string]: unknown } = {}
): Promise<void> {
  return new Promise((resolve) => {
    const timeoutMs = 2000
    enqueue('gtm.trackEvent', {
      // Default to a 2 second timeout to ensure the promise resolves even if the tag doesn't fire.
      // This is in the first object, to allow data to override this option, whereas the event and eventCallback
      // should not be allowed to be overridden.
      // https://www.simoahava.com/gtm-tips/use-eventtimeout-eventcallback/
      eventTimeout: timeoutMs,
      ...data,
      event: eventName,
      eventCallback: resolve
    })

    // If GTM is loaded, but for some reason doesn't fire the callback, ensure that we'll resolve after timeoutMs
    setTimeout(resolve, timeoutMs)
  })
}
