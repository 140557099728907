import { TrackOptions, TrackProperties } from '@/analytics/types'
import { enqueue } from '@/utils/queues/pc-queues'
import { WoopraTrackData, WoopraUserData } from '@/analytics/woopra'

const track = function (
  event: string,
  properties: TrackProperties,
  options?: TrackOptions,
  callback?: () => void
): void {
  enqueue('analytics.track', event, properties, options, callback)
}
const woopraIdentify = function (userData: WoopraUserData): void {
  enqueue('woopra.identify', userData)
}
const woopraTrack = function (
  action: string,
  properties: WoopraTrackData
): void {
  enqueue('woopra.track', action, properties)
}

export const analytics = {
  track,
  woopraIdentify,
  woopraTrack
}
