import {
  changePasswordAdapter,
  forgotPasswordAdapter,
  signInAdapter,
  signOutAdapter,
  createAccountAdapter,
  updateEmailAdapter,
  updateNameAdapter,
  resetPasswordAdapter
} from '@/api/account-adapter'
import session from '@/auth/models/session'
import channel from '@/auth/channel'
import {
  AccountNotFound,
  DuplicatedAccount,
  ErrorParsingResponse
} from '@/api/error'
import * as logging from '@/utils/logging'
import userProfile, { SignupParams } from '@/analytics/userProfile'
import {
  trackCreatedAccount,
  trackCreatedAccountErrorParsingResponse,
  trackCreatedAccountFailed,
  trackCreatedAccountAjaxRpcError,
  trackCreatedAccountDuplicateAccountError,
  trackSignedIn,
  trackSignedOut,
  trackChangedPassword,
  trackChangedPasswordError,
  trackResetPassword,
  trackResetPasswordEmailNotFoundError,
  trackResetPasswordAjaxError,
  trackCreatedAccountReferrer,
  trackChangedEmail,
  trackChangedEmailError,
  trackChangedName,
  trackChangedNameError
} from '@/services/analytics'

/**
 * Data related with user's account
 */
export interface SignInResponse {
  firstName: string
  lastName: string
}

/**
 * Authenticate users to be able to make other api calls
 */
export const signIn = async (
  email: string,
  password: string
): Promise<SignInResponse> => {
  const signInResponse = await signInAdapter(email, password)
  const { firstName, lastName, accessToken: token } = signInResponse
  await session.signIn({ firstName, lastName, token, email })

  // Track (in the background) after session is updated, to ensure that user data is set first
  trackSignedIn({ method: 'Password' })

  return { firstName, lastName }
}

/**
 * The referrer selected option
 */
export type Referrer =
  | 'saw-pc-card'
  | 'friend'
  | 'search'
  | 'blog'
  | 'facebook'
  | 'twitter'
  | 'mom-parent-group'
  | 'magazine'
  | 'newspaper-tv'
  | 'pinterest'
  | 'catalog'
  | 'other'

/**
 * Interface representing the parameters for creating an account.
 */
export interface CreateAccountParameters {
  email: string
  password: string
  firstName: string
  lastName: string
  /**
   * The signup parameters coming from url query
   */
  signupParams: SignupParams
  referrer: Referrer
  /**
   * The other referrer information (if applicable).
   */
  referrerOther: string
}

/**
 * Creates a new account with the given signup params, email, password, and optional referrer info
 * @param signupParams - Parameters for the signup request
 * @param referrer - User-provided referrer for the account creation, if any
 * @param referrerOther - User-provided referrer for the account creation if referrer is 'other', undefined otherwise
 * @returns A promise that resolves with the user's first and last name, and enriched user data on successful account creation
 * @throws An error if there was an issue creating the account
 */
export const createAccount = async ({
  signupParams,
  email,
  firstName,
  lastName,
  password,
  referrer,
  referrerOther
}: CreateAccountParameters): Promise<void> => {
  try {
    const createAccountAdapterResponse = await createAccountAdapter({
      signupParams,
      email,
      firstName,
      lastName,
      password,
      referrer,
      referrerOther
    })

    await session.signIn({
      firstName,
      lastName,
      email,
      token: createAccountAdapterResponse.accessToken
    })

    // Add referrer, referrerOther, and newAccount to resolve data
    if (referrer) {
      trackCreatedAccountReferrer({ referrer, referrerOther })
    }

    trackCreatedAccount({
      ...signupParams,
      email,
      firstName,
      lastName,
      referrer,
      referrerOther,
      method: 'Password'
    })
  } catch (createAccountError) {
    if (createAccountError instanceof ErrorParsingResponse) {
      logging.error(
        'Create Account in Login Modal failed / error parsing response',
        {
          response: createAccountError,
          userModel: {
            signupParams,
            email,
            firstName,
            lastName,
            password,
            referrer,
            referrerOther
          }
        }
      )
      trackCreatedAccountErrorParsingResponse()
    } else if (createAccountError instanceof DuplicatedAccount) {
      trackCreatedAccountDuplicateAccountError()
    } else if (
      createAccountError instanceof Error &&
      typeof createAccountError.cause === 'object'
    ) {
      const { textStatus, errorThrown } = createAccountError.cause as {
        textStatus: string
        errorThrown: string
      }
      trackCreatedAccountAjaxRpcError({ textStatus, errorThrown })
    } else {
      trackCreatedAccountFailed()
    }
    throw createAccountError
  }
}

/**
 * Sends a request to request a new password associated with the given email address.
 * @throws {AccountNotFound} If the email address is not found in the system.
 * @throws {*} If there is any other error during the password reset process.
 * @returns {Promise<void>} It doesn't return any util data
 */
export const forgotPassword = async (email: string): Promise<void> => {
  try {
    await forgotPasswordAdapter(email)
    trackResetPassword()
  } catch (error) {
    if (error instanceof AccountNotFound) {
      trackResetPasswordEmailNotFoundError()
    } else if (error instanceof Error && typeof error.cause === 'object') {
      const { textStatus, errorThrown } = error.cause as {
        textStatus: string
        errorThrown: string
      }
      trackResetPasswordAjaxError({ textStatus, errorThrown })
    }
    throw error
  }
}

export const resetPassword = resetPasswordAdapter

/**
 * Resolves a promise once the user is signed out, even if they weren't already signed in.
 */
export const signOut = async (): Promise<void> => {
  // Sign the user out of their Avetti session.
  // They may or may not already be signed in since the token will persist forever,
  // but this just makes sure their Avetti session is invalidated either way.
  await signOutAdapter()
  channel.trigger('signOut')
  trackSignedOut()
}

export const changePassword = async (
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  try {
    await changePasswordAdapter(currentPassword, newPassword)
    trackChangedPassword()
  } catch (error) {
    if (error instanceof Error && typeof error.cause == 'object') {
      const { errorMessages } = error.cause as {
        errorMessages: string[]
      }
      errorMessages.forEach((errorMessage) =>
        trackChangedPasswordError({ message: errorMessage })
      )
    }
    throw error
  }
}

/**
 * Updates the user's email address and performs necessary backend updates and resets.
 * @returns A promise that resolves when the email update is complete.
 */
export const updateEmail = async (
  existingEmail: string,
  newEmail: string
): Promise<void> => {
  try {
    // TODO: Remove extra sign out once Avetti is no longer used
    // Sign out of Avetti *before* changing the email, otherwise basket persistence will break,
    // and signing out will fail.
    await signOutAdapter()
    // Update the email via Mondrian. We still have a JWT because only the Avetti session was signed out.
    await updateEmailAdapter(existingEmail, newEmail)

    channel.trigger('changeEmail', newEmail)

    await userProfile.setUserDataV2(
      {
        email: newEmail
      },
      {
        // Skip backend update since we're already doing that above
        isExistingMarketoAccount: true,
        // Sets a cookie to prepopulate the new email when signing back in
        isFullCommerceAccount: true
      }
    )

    await signOut()
    // Reset password, because the old one will no longer work with the new email address.
    // The adapter is used directly instead of calling our service method, because we don't want to
    // log analytics events. This ensures that analytics represent actions that the user
    // explicitly took, so the stats are not skewed by the automated reset.
    await forgotPasswordAdapter(newEmail)
    trackChangedEmail({ existingEmail, newEmail })
  } catch (error) {
    logging.error('Email change failed', {
      error
    })
    trackChangedEmailError({
      message: String(error),
      existingEmail,
      newEmail
    })
    throw error
  }
}

/**
 * Updates the name of the user with the given email address.
 * @returns - A Promise that resolves with no result when the name is updated successfully or rejects with an error if the update fails.
 */
export const updateName = async (
  email: string,
  firstName: string,
  lastName: string
): Promise<void> => {
  try {
    await updateNameAdapter(email, firstName, lastName)
    channel.trigger('changeName', firstName, lastName)
    userProfile.setUserDataV2(
      {
        name: `${firstName} ${lastName}`,
        email
      },
      {
        // Skip backend update since we're already doing that above
        isExistingMarketoAccount: true
      }
    )
    trackChangedName({ firstName, lastName })
  } catch (error) {
    logging.error('Name change failed', {
      error
    })
    trackChangedNameError({ message: String(error), firstName, lastName })
    throw error
  }
}
