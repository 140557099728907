import { camelToSnakeCase, snakeToCamelCase } from '@/utils/stringUtils'

/**
 * Converts an object with keys in camel case to snake case.
 */
export function camelToSnake(obj: { [key: string]: unknown }): {
  [key: string]: unknown
} {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  return Object.entries(obj).reduce(
    (snakeObj: { [key: string]: unknown }, [key, value]) => {
      const snakeKey = camelToSnakeCase(key)
      snakeObj[snakeKey] = Array.isArray(value)
        ? value.map(camelToSnake)
        : camelToSnake(value as { [key: string]: unknown })
      return snakeObj
    },
    {}
  )
}

/**
 * Converts an object with keys in snake case to camel case.
 */
export function snakeToCamel(obj: { [key: string]: unknown }): {
  [key: string]: unknown
} {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  return Object.entries(obj).reduce(
    (camelObj: { [key: string]: unknown }, [key, value]) => {
      const camelKey = snakeToCamelCase(key)
      camelObj[camelKey] = Array.isArray(value)
        ? value.map(snakeToCamel)
        : snakeToCamel(value as { [key: string]: unknown })
      return camelObj
    },
    {}
  )
}
/**
 * Helper for `flatten` to accumulate a flattened object.
 *
 * Array and object values accumulate the resulting object from `flatten`,
 * and simple values are assigned directly to the accumulator.
 */
export const reduceWithKey = (
  flattenedKey: string,
  acc: { [s: string]: unknown },
  value: unknown
) => {
  typeof value === 'object' && value !== null
    ? Object.assign(
        acc,
        flatten(value as unknown[] | { [s: string]: unknown }, flattenedKey)
      )
    : (acc[flattenedKey] = value)
  return acc
}

/**
 * Returns a flat object with combined keys, in the format `foo.bar[0].firstItem`.
 *
 * Often useful to prepare nested data or arrays for KV stores, i.e. non-nested maps.
 */
export const flatten = (
  object: { [s: string]: unknown } | unknown[],
  parentKey?: string
): { [s: string]: unknown } =>
  Array.isArray(object)
    ? object.reduce(
        (flattened: { [s: string]: unknown }, value, i) =>
          reduceWithKey((parentKey || '') + `[${i}]`, flattened, value),
        {}
      )
    : Object.entries(object).reduce(
        (flattened, [key, value]) =>
          reduceWithKey(
            (parentKey ? parentKey + '.' : '') + key,
            flattened,
            value
          ),
        {}
      )
