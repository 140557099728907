import { FetchContext, FetchResponse, ofetch } from 'ofetch'
import { camelToSnake, snakeToCamel } from '@/utils/objectUtils'
import storage from '@/utils/storage'
import { InvalidAuthenticationTokenError, UnexpectedError } from '@/api/error'
import { AuthenticationResponse } from '@/api/mercury/account'
import { exception } from '@/utils/logging'

/**
 * Makes an asynchronous HTTP request, parse the response,
 * and the request to transform the keys of the data object to match between
 * camelCasing and snake_case.
 * @returns A Promise that resolves to the API response.
 */
const ofetchInstance = ofetch.create({
  async onRequest(request) {
    if (request.options.method === 'GET') {
      request.options.query = camelToSnake(
        request.options.query as { [key: string]: unknown }
      )
    } else {
      request.options.body = camelToSnake(
        request.options.body as { [key: string]: unknown }
      )
    }
  },
  onResponse({
    response
  }: FetchContext & { response: FetchResponse<ResponseType> }) {
    response._data = snakeToCamel(response._data as { [key: string]: unknown })
  }
})

/**
 * Interface for the API options.
 */
interface FetchMercuryOptions {
  method: string
  headers: { [key: string]: string }
  body?: { [key: string]: unknown }
  query?: { [key: string]: unknown }
}

/**
 * Makes an asynchronous HTTP request to the Mercury API.
 * @returns A Promise that resolves to the API response.
 */
export function fetchMercury<T>({
  method = 'GET',
  path,
  accessToken,
  data
}: {
  method?: string
  path: string
  accessToken?: string
  data?: { [key: string]: unknown }
}): Promise<T> {
  const options: FetchMercuryOptions = {
    method,
    headers: {
      ...(accessToken && {
        Authorization: `Bearer ${accessToken}`
      })
    }
  }

  if (data) {
    if (method === 'GET') {
      options.query = data
    } else {
      options.body = data
    }
  }

  return ofetchInstance<T>(path, options)
}

/**
 * Interface for the JWT response what we use.
 */
export interface JwtPayload {
  /**
   * Email address of the Mercury user.
   */
  email: string
  /**
   * Mercury user ID.
   */
  sub: number
  /**
   * Expiration time as seconds since Epoch.
   */
  exp: number
  /**
   * Issued at time as seconds since Epoch.
   */
  iat: number
  /**
   * Issuer
   */
  iss: 'solidus'
}

/**
 * parses and JWT token from the API response to get the payload.
 */
export const parseJwt = (accessToken: string): JwtPayload => {
  try {
    // JWT should always be in a valid format, but any parsing issues are caught below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return JSON.parse(atob(accessToken.split('.')[1]!))
  } catch (e) {
    const error = new UnexpectedError(e)
    exception(error)
    throw error
  }
}

/** Persistence format for Mercury access token, refresh token, and related metadata. */
export type MercuryTokensJson = {
  accessToken: string
  refreshToken: string
  /** Refresh token expiration time as seconds since Epoch. */
  refreshTokenExpiresAt: number
}

export class MercuryTokens {
  private static readonly STORAGE_KEY = 'mercuryTokens'
  /** Refresh tokens are valid for 30 days after creation. This value matches the backend configuration. */
  private static readonly REFRESH_TOKEN_EXPIRATION_SECONDS = 2592000

  private static newRefreshTokenExpirationDate(): Date {
    return new Date(
      Date.now() + MercuryTokens.REFRESH_TOKEN_EXPIRATION_SECONDS * 1000
    )
  }

  private static load(): MercuryTokens | null {
    const tokens = storage.getItem(this.STORAGE_KEY) as MercuryTokensJson | null
    if (tokens) {
      return new this(
        tokens.accessToken,
        tokens.refreshToken,
        new Date((tokens.refreshTokenExpiresAt as number) * 1000)
      )
    } else {
      return null
    }
  }

  static async loadWithUnexpiredAccessToken(): Promise<MercuryTokens | null> {
    const tokens = MercuryTokens.load()
    if (!tokens) {
      return null
    } else if (tokens.isAccessTokenExpired) {
      return tokens.refresh()
    } else {
      return tokens
    }
  }

  /**
   * Create a new MercuryTokens instance from an API response.
   * refreshTokenExpiresAt will be set relative to now, assuming the API response was just returned.
   * The tokens will be persisted so they can be loaded in the future.
   */
  static create(accessToken: string, refreshToken: string): MercuryTokens {
    const tokens = new MercuryTokens(
      accessToken,
      refreshToken,
      this.newRefreshTokenExpirationDate()
    )
    tokens.save()
    return tokens
  }

  /**
   * Removes the tokens from storage. Effectively signs the user out by deleting the credentials.
   */
  static remove(): void {
    storage.removeItem(this.STORAGE_KEY)
  }

  private constructor(
    readonly accessToken: string,
    readonly refreshToken: string,
    readonly refreshTokenExpiresAt: Date
  ) {}

  get accessTokenPayload(): JwtPayload {
    return parseJwt(this.accessToken)
  }

  get accessTokenExpiresAt(): Date {
    return new Date(this.accessTokenPayload.exp * 1000)
  }

  get isAccessTokenExpired(): boolean {
    return this.accessTokenExpiresAt < new Date()
  }

  get isRefreshTokenExpired(): boolean {
    return this.refreshTokenExpiresAt < new Date()
  }

  private save(): void {
    const mercuryTokens: MercuryTokensJson = {
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
      refreshTokenExpiresAt: Math.floor(
        this.refreshTokenExpiresAt.getTime() / 1000
      )
    }
    storage.setItem(MercuryTokens.STORAGE_KEY, mercuryTokens)
  }

  /**
   * Request new tokens from the API, using the refresh token.
   *
   * If the user's email address changes, this should be called to get a new
   * access token with the updated email address in the payload.
   *
   * @returns New MercuryTokens instance with new tokens from the API.
   *
   * @throws {@link InvalidAuthenticationTokenError}
   * Thrown if the refresh token was expired, or not accepted by the API.
   */
  async refresh(): Promise<MercuryTokens> {
    if (this.isRefreshTokenExpired) {
      throw new InvalidAuthenticationTokenError()
    }
    try {
      const response = await fetchMercury<AuthenticationResponse>({
        path: '/mercury/api/oauth/token.json',
        data: { refreshToken: this.refreshToken, grantType: 'refresh_token' }
      })
      const updatedTokens = MercuryTokens.create(
        response.accessToken,
        response.refreshToken
      )
      updatedTokens.save()
      return updatedTokens
    } catch (error) {
      // TODO: Only throw InvalidAuthenticationTokenError if there was a problem with the refresh token
      throw new InvalidAuthenticationTokenError()
    }
  }
}
