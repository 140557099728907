import _ from 'underscore'

/**
 * Get the price per unit for a given quantity.
 */
export const getPriceAtQuantity = (
  prices: number[],
  priceQuantities: number[],
  targetQuantity: number
): number => {
  // Algorithm should match Item.scala in Mondrian
  const quantityIndex = _.findLastIndex(
    priceQuantities,
    (priceQuantity) => priceQuantity < targetQuantity
  )
  const priceIndex = quantityIndex + 1
  // Data will always have exactly 1 more price than quantity
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return prices[priceIndex]!
}
