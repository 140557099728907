export default {
  format(error: { message: string; code: string }) {
    return `${error.message} (error code: ${error.code})`
  },

  errors: {
    // 1xxx: Front-end only

    // 2xxx: Mondrian
    getItem: {
      message: 'Failed to load item data',
      code: 'E2000'
    },
    getItemEditor: {
      message: 'Failed to load editor data',
      code: 'E2001'
    },
    upsertProof: {
      message: 'Failed to upsert proof',
      code: 'E2002'
    },
    getItemByVendorItemIdAndVendorThemeId: {
      message: 'Failed to load item data',
      code: 'E2003'
    },
    cancelOrder: {
      message: 'Failed to cancel your order',
      code: 'E2004'
    },
    getProof: {
      message: 'Failed to load proof data',
      code: 'E2005'
    },

    // 3xxx: Commerce
    updateCart: {
      message: 'Failed to update the cart',
      code: 'E3000'
    },

    // 4xxx: External Partners
    createMediaclipProject: {
      message: 'Failed to create project',
      code: 'E4000'
    },
    getMediaclipProject: {
      message: 'Failed to load project data',
      code: 'E4001'
    },
    createHubStoreUserToken: {
      message: 'Failed to obtain user token',
      code: 'E4002'
    },
    consolidateMediaclipGuestUser: {
      message: 'Failed to consolidate guest user id',
      code: 'E4003'
    },
    launchMediaclipEditor: {
      message: 'Failed to launch editor',
      code: 'E4004'
    }
  }
}
