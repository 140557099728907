import $ from 'jquery'
import { error as logError } from '@/utils/logging'

/** Unique ID for our Munchkin tracking data, assigned by Marketo */
const MUNCHKIN_ID = '961-ARE-605'

/**
 * Helps ensure we don't log page data twice.
 * In personalization, we log page data after loading a product,
 * but that may happen before or after Munchkin initializes.
 */
let pageDataTracked = false

/** Allows asynchronous collection of events before Marketo has loaded */
const events = []

const marketo = {
  /** Key of the cookie with a unique ID generated by Munchkin for tracking and associating web activity */
  TRACKING_COOKIE: '_mkto_trk',

  init() {
    $.ajax({
      url: '//munchkin.marketo.net/munchkin.js',
      dataType: 'script',
      cache: true,
      success() {
        // Sometimes Munchkin fails to load so we skip initialization
        if (typeof window.Munchkin !== 'object') {
          logError('Could not initialize Munchkin after loading script', {
            typeofMunchkin: typeof window.Munchkin
          })
          return
        }

        window.Munchkin.init(MUNCHKIN_ID, {
          // Sends tracking data via async XHR instead of synchronously
          asyncOnly: true,
          // Overrides the default delay of 350 ms to improve (i.e. not ruin) responsiveness.
          // Any delay should also be unnecessary since modern browsers will use the Beacon API.
          clickTime: 0,
          httpsOnly: true
        })

        // Now that Munchkin has been initialized, execute all events that we queued up before the library loaded
        marketo.flushEvents()

        // Pages can defined a global called _mktoPageData to track events for that page,
        // but only if no page data has been tracked already.
        if (!pageDataTracked) {
          if (!marketo.trackPageData()) {
            // If no page data existed during initialization, try again after DOM ready
            // so all JavaScript on the page has been parsed. This 2 attempt approach ensures
            // we capture as much data as possible for users that leave the page before DOM ready.
            $(function () {
              if (!pageDataTracked) {
                marketo.trackPageData()
              }
            })
          }
        }
      }
    })
  },

  trackPageData() {
    if (typeof window._mktoPageData === 'object') {
      // Log events with page data under /_pageView/ so we can differentiate between page views
      // and other events. Behavioral scoring will increment when certain majorCategory or minorCategory
      // values are seen in /_pageView/ events, but not in /_event/ events.
      marketo.trackEvent(window._mktoPageData, '/_pageView/')
      pageDataTracked = true
      return true
    }
    return false
  },

  // We'll call this after init to fire off all queued events that were queued up
  flushEvents() {
    $.each(events, function (i, event) {
      marketo.trackEvent(event)
    })
  },

  // Marketo doesn't support tracking events so we log all events to a single URL and pass data as params
  trackEvent(eventData, eventPath) {
    // Log events under /_event/ by default, but /_pageView/ will be used supplemental page view data
    eventPath = eventPath || '/_event/'

    // Supplement eventData with page data for context
    let allData = {
      path: location.pathname,
      pageTitle: document.title
    }
    if (location.hash) {
      allData.hash = location.hash
    }
    // Send any available _mktoPageData with all events, but explicit event data always takes precedence
    allData = $.extend({}, window._mktoPageData, allData, eventData)

    // Remove certain data from the URL to attempt to make activity tracking more reliable.
    // This is an attempt at a temporary workaround to remove certain params from the URL, which seem
    // to trigger a weird Marketo bug that makes activities get dropped in some scenarios.
    // This seems to make logging events more reliable, but sometimes the triggers still don't work,
    // which we'll have to investigate separately. We'll likely come up with a completely new event
    // logging architecture instead of using these events for campaigns until we get all the issues fixed,
    // but until then, this at least makes a few things run better.
    const urlData = $.extend({}, allData)
    delete urlData.path
    delete urlData.pageTitle

    const event = {
      // Copy the query string to the hash (which just becomes part of the URL in Marketo).
      // This allows us to perform basic boolean AND logic by giving us access to the query string values
      // through the URL. This is useful because Choices in Flow steps in Marketo can refer back to any
      // historically visited URL, and webhooks have access to {{trigger.Web Page}}.
      // Visited Web Page (URL), but they can't refer back to the "querystring" (in Marketo terms).
      url: eventPath + '#' + $.param(urlData),
      params: $.param(allData)
    }
    if (typeof window.mktoMunchkinFunction === 'function') {
      window.mktoMunchkinFunction('visitWebPage', event)
    } else {
      // If Marketo hasn't loaded yet, queue up events to fire off after initialization
      events.push(event)
    }
  }
}

export default marketo
