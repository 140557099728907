import $ from 'jquery'
import cookies from '@/utils/cookies'
import marketo from '@/analytics/marketo'
import { DuplicatedAccount } from '@/api/error'
import { withSigninUiOptions } from '@/utils/signinUiOptions'

export interface SampleRequest {
  /**
   * Must be a picklist value of `SampleRequest__c.Type__c` in Salesforce
   */
  type: string
  /**
   * Email of the Contact to associate the sample request with
   */
  email: string
  firstName?: string
  lastName?: string
  phone?: string
  /**
   * For a wedding, this may be rustic, beach, modern.
   */
  eventStyle?: string
  eventDate?: string
  guestCount?: number
  address1?: string
  address2?: string
  address3?: string
  city?: string
  state?: string
  zipCode?: string
  /**
   * True if the request is ready to be fulfilled. Free sample requests are immediately fulfillable,
   * while paid sample requests will be updated to be fulfillable once paid for.
   */
  isFulfillable?: boolean
}

interface AccountUpsert {
  /**
   * Customer's first name. If the user has a full commerce account, this will only be updated
   * if the user is authenticated.
   */
  firstName?: string
  /**
   * Customer's last name. If the user has a full commerce account, this will only be updated
   * if the user is authenticated.
   */
  lastName?: string
  /**
   * Updated email of the customer for an email address change.
   * This will only be updated if the user is authenticated and cannot be used with a lead.
   */
  email?: string
  /**
   * Wedding date of the customer. This is always writable for unauthenticated users since
   * it's not sensitive.
   */
  weddingDate?: string
  /**
   * _mkto_trk cookie set by Munchkin, which is pushed into a JSON array in the Salesforce
   * Contact to later be associated with the customer's email.
   */
  marketoTrackingCookie?: string
  /**
   * Short name for the reason we collected the customer's email. This can later be used
   * in marketing to explain how a person got on our email list.
   */
  emailCollectionSource?: string
  /**
   * User agent of the browser the customer was using at initial account creation time.
   * Unlike other signup params, this is collected when the account is initially created,
   * and not when we first see the user. However, this since we only track users within
   * their specific browser instance, the only thing that could theoretically change
   * between when we first saw the user and when they eventually gave us their email,
   * would be the browser or operating system version (which we don't care about for
   * business purposes).
   */
  signupUserAgent?: string
  /**
   * Referer when we first saw the customer
   */
  signupReferer?: string
  /**
   * Each banner ad has a unique name which will represent the size and creative ID,
   * which can be passed in query string parameter pc_cdesc
   */
  signupCreativeDescription?: string
  /**
   * Bidding strategy such as CPC or CPM from query string parameter pc_class
   */
  signupClass?: string
  /**
   * Destination landing page for the ad from query string parameter pc_dest
   */
  signupDestination?: string
  /**
   * Targeting attribute or product attribute from query string parameter pc_seg
   */
  signupSegment?: string
  /**
   * utm_campaign query string parameter
   */
  signupUtmCampaign?: string
  /**
   * utm_content query string parameter
   */
  signupUtmContent?: string
  /**
   * utm_medium query string parameter
   */
  signupUtmMedium?: string
  /**
   * utm_source query string parameter
   */
  signupUtmSource?: string
  /**
   * utm_term query string parameter
   */
  signupUtmTerm?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c1
   */
  signupCustom1?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c2
   */
  signupCustom2?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c3
   */
  signupCustom3?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c4
   */
  signupCustom4?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c5
   */
  signupCustom5?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c6
   */
  signupCustom6?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c7
   */
  signupCustom7?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c8
   */
  signupCustom8?: string
  /**
   * Custom data slot for collecting campaign signup data from query string parameter pc_c9
   */
  signupCustom9?: string
  /**
   * Optional list of Sample Requests to insert after the Account. Sample requests are always
   * inserted and never updated (unlike account-level in `AccountUpsert`).
   * Typically this would only have a single Sample Request if it was populated,
   * but taking a Seq allows for greater future flexibility.
   */
  sampleRequests?: SampleRequest[]
}

/**
 * Inserts or updates an account.
 * This may also insert sample requests if present in `accountUpsert`.
 * If the Marketo cookie is present, the backend associate it (and therefore all site activity) with the lead.
 *
 * @param email Email address of the lead or customer. For updates, this is the existing email.
 * @param accountUpsert Account data to insert or update. Some fields are immutable (and may be ignored)
 * while other fields for users with a full account require authentication to update.
 * @param forceAuthentication Ensures that the API request is authenticated.
 * The user will be forced to sign in if they aren't already. Set this to `true` when updating protected fields
 * that can't be updated anonymously.
 * @see {@link http://localhost:9000/docs/swagger-ui/index.html?url=/docs/swagger.json#/accounts/upsert | Mondrian API Docs for Account Upsert}
 */
export const upsertAccount = (
  email: string,
  accountUpsert: AccountUpsert,
  forceAuthentication: boolean
): JQuery.jqXHR => {
  return withSigninUiOptions((signinOptions) => {
    if (forceAuthentication) {
      Object.assign(signinOptions, {
        signinOnly: true,
        forceUsername: true,
        email
      })
    }
    const requestData = {
      method: 'PATCH',
      url: '/api/v2/accounts/' + encodeURIComponent(email),
      data: JSON.stringify(accountUpsert),
      contentType: 'application/json',
      authenticate: forceAuthentication
    }
    return $.ajax(requestData).promise()
  })
}

/**
 * Updates a full account's name. Although we may allow name changes in certain lead-specific scenarios,
 * this method is only intended to update a customer's name for an existing full account.
 * The user will be prompted to sign in if they do not have an active session.
 *
 * @param email Email address of an existing customer who has a full account (with a password).
 * @param firstName Updated first name.
 * @param lastName Updated last name.
 *
 * @see {@link http://localhost:9000/docs/swagger-ui/index.html?url=/docs/swagger.json#/accounts/upsert | Mondrian API Docs for Account Upsert}
 */
export const updateName = (
  email: string,
  firstName: string,
  lastName: string
): JQuery.jqXHR => {
  return upsertAccount(
    email,
    {
      firstName,
      lastName
    },
    true
  )
}

/**
 * Updates an account's email.
 * The user will be prompted to sign in if they do not have an active session.
 *
 * @param existingEmail Email address of an existing customer.
 * @param newEmail Updated email.
 *
 * @see {@link http://localhost:9000/docs/swagger-ui/index.html?url=/docs/swagger.json#/accounts/upsert | Mondrian API Docs for Account Upsert}
 */
export const updateEmail = (
  existingEmail: string,
  newEmail: string
): JQuery.jqXHR => {
  return upsertAccount(
    existingEmail,
    {
      email: newEmail,
      marketoTrackingCookie: cookies.getItem(marketo.TRACKING_COOKIE)
    },
    true
  ).then(null, (jqXHR) => {
    if (jqXHR.status === 409) {
      return new DuplicatedAccount()
    } else {
      return jqXHR
    }
  }) as JQuery.jqXHR
}
