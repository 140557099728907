import { WoopraTrackData } from '@/analytics/woopra'
import { camelToSnakeCase } from '@/utils/stringUtils'

/**
 * Converts camelCase keys of an object to snake_case.
 *
 * ONLY use this for legacy actions for backwards compatibility with existing data.
 * Any new events can be sent without transformation,
 * but Woopra will automatically convert keys to lowercase.
 */
export const legacyWoopraKeysToSnake = function (object: WoopraTrackData) {
  const convertedObject: WoopraTrackData = {}
  for (const key in object) {
    const value = object[key]
    const snakeKey = camelToSnakeCase(key)
    convertedObject[snakeKey] = Array.isArray(value)
      ? value.map(legacyWoopraKeysToSnake)
      : value
  }
  return convertedObject
}

/**
 * Helper for `flatten` to accumulate a flattened object for Woopra.
 *
 * Logic differs from the general purpose helper in `objectUtils` due to
 * special behavior for Woopra.
 *
 * Allows a native array at the root.
 * Filters out keys for values that are `undefined`, `null`, or blank.
 */
const reduceWithKey = (
  flattenedKey: string,
  acc: WoopraTrackData,
  value: unknown,
  root?: boolean
) => {
  if (typeof value === 'object' && value !== null) {
    if (root && Array.isArray(value)) {
      // Allow a native array at the root
      acc[flattenedKey] = value.map((v) =>
        typeof v === 'object' ? flattenForWoopra(v, '', false) : v
      )
    } else {
      acc = {
        ...acc,
        ...flattenForWoopra(
          value as unknown[] | WoopraTrackData,
          flattenedKey,
          false
        )
      }
    }
  } else if (value !== undefined && value !== null && value !== '') {
    acc[flattenedKey] = value
  }
  return acc
}

/**
 * Returns a flat object with combined keys, in the format `foo.nested.bar[0].firstItem`.
 *
 * Filters out keys for values that are `undefined`, `null`, or blank.
 *
 * Array values for root-level keys will not be flattened because Woopra has support (beta as of 8/2023)
 * for arrays as collection values, but further nesting isn't supported.
 *
 * CAUTION: Keys must be unique regardless of case because Woopra will automatically convert all keys to lowercase.
 */
export const flattenForWoopra = (
  object: WoopraTrackData | unknown[],
  parentKey?: string,
  root: boolean = true
) =>
  Array.isArray(object)
    ? object.reduce(
        (flattened, value, i) =>
          reduceWithKey((parentKey || '') + '-' + i, flattened, value, root),
        {}
      )
    : Object.entries(object).reduce(
        (flattened, [key, value]) =>
          reduceWithKey(
            (parentKey ? parentKey + '.' : '') + key,
            flattened,
            value,
            root
          ),
        {}
      )
