import imgix from 'imgix'
import { encode } from '@/utils/base64Url'

// Add global defaults
const extend = function (object, properties) {
  for (const key in properties) {
    const val = properties[key]
    object[key] = val
  }
  return object
}
const getImgixParams = function (element) {
  const imgixData =
    element.dataset === undefined
      ? element.getAttribute('data-imgix-params')
      : element.dataset.imgixParams
  if (imgixData) {
    return JSON.parse(imgixData)
  } else {
    return {}
  }
}
const hasClass = (el, clazz) =>
  el.className.match(new RegExp('(?:^|\\s)' + clazz + '(?:\\s|$)'))
//removeClass = (el, clazz) ->
//  if hasClass(el, clazz)
//    classRegex = new RegExp('(?:^|\\s)'+ clazz + '(?:\\s|$)')
//    el.className = el.className.replace classRegex, ' '
const addClass = function (el, clazz) {
  if (!hasClass(el, clazz)) {
    return (el.className += ' ' + clazz)
  }
}

const isMobileViewport = () =>
  (window.innerWidth > 0 ? window.innerWidth : screen.width) < 768
imgix.pcDefaults = {
  autoInsertCSSBestPractices: true,
  onChangeParamOverride(elementWidth, elementHeight, params, element) {
    const elementParams = getImgixParams(element)
    if (
      isMobileViewport() &&
      (elementParams.viewport != null
        ? elementParams.viewport.mobile
        : undefined)
    ) {
      extend(elementParams, elementParams.viewport.mobile)
    }
    // Delete our own keys to prevent passing them to imgix, or else we'll have "'src' is an invalid imgix param" warnings
    delete elementParams.viewport
    delete elementParams.src
    extend(params, elementParams)

    // Don't upscale images beyond their original size
    params.fit = params.fit || 'max'

    // Choose optimal format by browser
    params.auto = params.auto || 'format'

    // Force height based on dynamic width if crop is the goal
    if (params.fit === 'crop' && params.aspect_ratio) {
      params.h = params.w / params.aspect_ratio
      // Can't delete this param that's only for our use because it will cause an
      // error in imgix.js when a key is removed. This won't matter but will
      // cause warnings to show in the console.
      params.aspect_ratio = ''
    }

    // Image was eagerly loaded and this isn't a high DPR screen
    if (params.q === '26' && !params.dpr) {
      // A low q is added for eagerly loaded images. For dpr > 1,
      // `q` is overridden by imgix.js due to highDPRAutoScaleQuality.
      // However, if dpr == 1, we want the non-eager version to come out at
      // default quality (75).
      // We choose a quality of 26 in _essence_picture_view for eagerly loaded
      // images as a special value (internal to PC) that indicates eager loading.
      params.q = ''
    }

    return params
  },
  onLoad(el) {
    if (hasClass(el, 'imgix-eager-lqip')) {
      // For now, this is only for transitions on eager LQIP images
      return addClass(el, 'imgix-loaded')
    }
  },
  lazyLoad: true,
  lazyLoadOffsetVertical: 300,
  // Make lazy carousel images load quickly when scrolling to the next one
  lazyLoadOffsetHorizontal: 1000,
  // Our biggest assets are 1920 and we don't want imgix.js to request bigger images for no reason,
  // as it will result in more downloads as people with huge screens resize their viewport.
  maxWidth: 1920,
  maxHeight: 1920,
  // Resize updates happen up to this often. This is half the default of 200,
  // but doesn't pay off as much as speeding up throttle and has a bigger penalty
  // since resizes are continuous.
  debounce: 100,
  // Reload will run up to this often. Effectively this controls how quickly we lazy load
  // images as we scroll. The default is 200.
  throttle: 50
}

/**
 * Matches `/api/v#/foo?query` where `?query` is optional.
 *
 * Groups from the above example:
 *   1. `/api/v#`
 *   2. `/foo`
 *   3. `query` (optional)
 */
const apiRequestPathAndQuery = /(^\/api\/v\d)(\/.*?)(?:\?(.*))?$/
const subdomain = window.location.hostname.split('.')[0]
const isImage = (url) => /\.(jpg|png|svg)$/.test(url.split('?')[0])

// Creates an imgix URL from a relative or absolute asset URL.
// Most image URLs are already relative coming from our JSON APIs. However, in QA, we upload images to Google Storage
// and return absolute URLs that need to be mapped to different imgix sources such as
// https://storage.googleapis.com/storage.paperculture.com/uploadsForTesting/73789b8f48d951e31ea2ab5d7a4f783b.cm.png.
imgix.toImgixUrl = function (src) {
  // src may be relative or absolute
  if (
    !src ||
    src.indexOf('.imgix.net/') !== -1 ||
    src.startsWith('http://') ||
    src.startsWith('//')
  ) {
    // url may already be something like //*.imgix.net/...
    return src
  } else if (src.startsWith('/')) {
    const apiRequestMatches = apiRequestPathAndQuery.exec(src)
    if (apiRequestMatches && isImage(src)) {
      const [, apiPrefix, pathRest, query] = apiRequestMatches
      const queryPathSegment = query ? `/q-${encode(query)}` : ''
      return `https://paperculture-image.imgix.net/${subdomain}${apiPrefix}${queryPathSegment}${pathRest}`
    } else {
      // Relative URLs are assumed to be on our default domain / www and therefore our default source
      return `//paperculture.imgix.net${src}`
    }
  } else if (
    src.indexOf('storage.googleapis.com/storage.paperculture.com') !== -1
  ) {
    // pc-storage maps to our Google Storage bucket for generated images
    return src.replace(
      'storage.googleapis.com/storage.paperculture.com',
      'pc-storage.imgix.net'
    )
  } else {
    // Give up
    return src
  }
}

imgix.autoDprUrl = function (url, width, height) {
  const imgSrcUrl = new imgix.URL(imgix.toImgixUrl(url))
  imgSrcUrl.setParams(imgix.helpers.getAutoDPRQuality())
  imgSrcUrl.setDPR(imgix.helpers.getDPR())
  imgSrcUrl.setFit('max')
  imgSrcUrl.setAuto('format')
  imgSrcUrl.setWidth(width)
  // height might be set especially when we're fitting high DPR content into a fixed area or tile
  if (height) {
    imgSrcUrl.setHeight(height)
  }
  return imgSrcUrl.getUrl()
}

// Ensure DPR is at least 1 so that we don't serve images that don't scale in our layout correctly
// if the browser is zoomed out.
const originalGetDPR = imgix.helpers.getDPR
imgix.helpers.getDPR = function () {
  const dpr = originalGetDPR()
  if (dpr < 1) {
    return 1
  } else {
    return dpr
  }
}

const originalGetImgDetails = imgix.FluidSet.prototype.getImgDetails
imgix.FluidSet.prototype.getImgDetails = function (elem, zoomMultiplier) {
  const isMobile = isMobileViewport()
  // If our viewport is different than before, then clear the cached `url` field
  if (elem._pcUrlIsMobile !== isMobile) {
    elem._pcUrlIsMobile = isMobile
    elem.url = null
  }
  return originalGetImgDetails.call(this, elem, zoomMultiplier)
}

// Auto-convert all URLs to imgix domains
const originalGetImgSrc = imgix.helpers.getImgSrc
imgix.helpers.getImgSrc = function (elem) {
  const url = isMobileViewport()
    ? getImgixParams(elem).viewport?.mobile?.src || originalGetImgSrc(elem)
    : originalGetImgSrc(elem)
  return imgix.toImgixUrl(url)
}

imgix.helpers.getAutoDPRQuality = function () {
  const dpr = imgix.helpers.getDPR()
  if (dpr > 1) {
    return { q: Math.min(Math.max(parseInt(100 / dpr, 10), 30), 75) }
  } else {
    return {}
  }
}

// Load images with imgix ASAP
imgix.onready(function () {
  imgix.pcPageImages = imgix.fluid(imgix.pcDefaults)
  imgix.pcPageDynCropImages = imgix.fluid(
    extend(
      { fluidClass: 'imgix-dyn-crop', updateOnResizeDown: true },
      imgix.pcDefaults
    )
  )
  imgix.pcPageFitImgToContainerHeight = imgix.fluid(
    extend(
      {
        fluidClass: 'imgix-fit-height',
        updateOnResizeDown: true,
        fitImgTagToContainerHeight: true
      },
      imgix.pcDefaults
    )
  )
})

imgix.pcUpdateImagePath = function (element, path) {
  if (!element || !path) {
    return
  }

  if (element.url?.urlParts) {
    element.url.urlParts.pathname = path
  }
  // Force an update by resetting last width
  element.lastWidth = 0
  // Load new image into cache and swap in when loaded
  return imgix.fluid(element)
}

imgix.pcUpdateImageUrl = function (element, url) {
  element.url = url
  // Force an update by resetting last width
  element.lastWidth = 0
  // Load new image into cache and swap in when loaded
  return imgix.fluid(element)
}

// Return library so this can be used in place of importing imgix
export default imgix
