import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import channel from '@/auth/channel'
import { urlParams } from '@/utils/url'
import getUserEmail from '@/analytics/getUserEmail'
import storage from '@/utils/storage'
import userProfile from '@/analytics/userProfile'
import { error as logError } from '@/utils/logging'

// Module syncing handlers
const UNSYNCED_MODULES_KEY = 'unsyncedModules'
const getUnsyncedModulesQueue = function () {
  const storedQueue = storage.getItem(UNSYNCED_MODULES_KEY)
  if (_.isArray(storedQueue)) {
    return storedQueue
  } else {
    return []
  }
}

const setUnsyncedModulesQueue = function (queue) {
  if (_.isEmpty(queue)) {
    // For empty queue, clean up local storage
    storage.removeItem(UNSYNCED_MODULES_KEY)
  } else {
    storage.setItem(UNSYNCED_MODULES_KEY, queue)
  }
}

// Adds a module to the sync queue.
// Modules should add themselves via syncModule:enqueue when they persist anonymous data without syncing to the server.
channel.reply('syncModule:enqueue', function (module) {
  const queue = getUnsyncedModulesQueue()
  if (queue.indexOf(module) === -1) {
    queue.push(module)
    return storage.setItem(UNSYNCED_MODULES_KEY, queue)
  }
})

// Remove a module from the sync queue.
// Modules should remove themselves via syncModule:dequeue when they're done syncing with the server.
channel.reply('syncModule:dequeue', function (module) {
  const queue = getUnsyncedModulesQueue()
  const moduleIndex = queue.indexOf(module)
  if (moduleIndex !== -1) {
    queue.splice(moduleIndex, 1)
    setUnsyncedModulesQueue(queue)
  }
})

class Session extends Backbone.Model {
  initialize() {
    const isAdminSignIn = urlParams().adminSignin
    if (!isAdminSignIn) {
      // Load session from local storage unless it's on the page due to an admin sign in
      this.loadFromLocalStorage()
    }

    this.listenTo(channel, 'signOut', this.onSignOut)
    this.listenTo(channel, 'changeEmail', (email) => {
      this.set({ email })
    })
    this.listenTo(channel, 'changeName', (firstName, lastName) => {
      this.set({
        firstName,
        lastName
      })
    })

    // Persist changes in local storage immediately
    this.on('change', this.save)

    if (isAdminSignIn) {
      // A CSR signed in as a customer.
      // The initial signin will land them in the cart with ?adminSignin=true.
      // We need to initialize their session using global variables from the page.
      // The admin flag will force us to clear everything on signout instead of remembering anything.
      this.set($.extend({ admin: true }, window.pcSession))
    } else {
      // When the email changes after initialization, save it to a cookie to prepopulate login in the future
      this.on('change:email', this.setUserData)
    }

    // Sync data from an anonymous session
    this.syncModules()
  }

  // Init model with token info from localStorage.
  // We might consider caching this for a second to reduce calls to disk if this is called
  // many times in quick succession. We'd have to clear the cache on signIn/Out to ensure freshness.
  // Currently, this isn't a bottleneck, so the optimizaton isn't worth it.
  loadFromLocalStorage() {
    this.set(storage.getItem('session'))
  }

  // Once this cookie is set, it means somebody has logged in successfully with this email.
  // If the cookie is set when sign in is presented, we'll default to sign in vs create account.
  // This also sends the data to tracking services immediately.
  // Params are mainly used to define options, because model data is fetched from context (this) so that
  // the caller doesn't need to pass in any params if options aren't used.
  setUserData(model, changedValue, options = {}) {
    // Get email from this instead of model param (which is optional)
    const email = this.get('email')
    // options.skipSetUserData can be passed in through @set to skip running this on a change event
    if (email && !options.skipSetUserData) {
      return userProfile.setUserData(
        email,
        this.getName(),
        undefined,
        _.pick(
          this.toJSON(),
          'firstName',
          'lastName',
          'email',
          'promotions',
          'admin'
        )
      )
    } else {
      // If there's nothing to do, still return a resolved promise so there's a consistent return type
      return $.Deferred().resolve().promise()
    }
  }

  // Save model to local storage
  save() {
    // Only save the essential keys.
    // Email is also encoded into token, but also having it as a property makes it more convenient.
    // promotions is not used currently, but we can later return a list of promotion objects
    // with customer-specific promotions.
    storage.setItem(
      'session',
      _.pick(
        this.toJSON(),
        'firstName',
        'lastName',
        'email',
        'token',
        'promotions',
        'admin'
      )
    )
  }

  /**
   * Returns `true` if the user is signed in, i.e. a token exists and is not expired.
   *
   * State will be refreshed from local storage automatically unless the model is currently changing
   * (e.g. in the middle of a `set`).
   */
  isSignedIn() {
    if (!this._changing) {
      // Reload session from local storage.
      // If the user signed in from another tab, this refreshes the session in the current tab.
      // This also ensures that a sign out is global.
      this.loadFromLocalStorage()
    }
    return this.get('token') && this.getTokenClaims().exp > new Date() / 1000
  }

  getTokenClaims() {
    // Parse token and return info if valid
    if (this.get('token')) {
      // Extract the JWT Claims from the JWS Payload. The first part is the header.
      return JSON.parse(atob(this.get('token').split('.')[1]))
    }
  }

  getClientId() {
    return this.getTokenClaims()?.cid
  }

  onSignOut() {
    // Mutate the model all ta once to ensure that only a single `change` event is fired to avoid consistency issues
    if (!this.get('admin')) {
      // Clear all attributes except `firstName`, `lastName`, `email`, which persist in the session for identification.
      // Since the `token` is removed, `isSignedIn()` will be `false` and the user won't be authenticated.
      this.set(
        Object.fromEntries(
          Object.keys(this.attributes)
            .filter((key) => !['firstName', 'lastName', 'email'].includes(key))
            // Hack to run `unset` logic for multiple keys at once, so there's a single `change` event
            .map((key) => [key, undefined])
        ),
        {
          unset: true
        }
      )
    } else {
      this.clear()
    }
    this._clearComponentStorage()
  }

  // Add cleanup for optional components like favorites, which won't always be loaded at sign out to observe the event.
  // syncModules should ensure that everything is already persisted during sign in so it's safe to clear everything.
  // Components like favorites may duplicate this logic to clear any in-memory collections as well as the persistence
  // clearing that happens here.
  _clearComponentStorage() {
    storage.removeItem('favorites')
    storage.removeItem('images')
    storage.removeItem('hubStoreUserToken')
    storage.removeItem('zendeskLoginJwt')
  }

  // Populate the session with sign-in data
  signIn(data) {
    // Email should always be all lowercase
    if (data.email) {
      data.email = data.email.toLowerCase()
    }
    // Skip calling setUserData in a change event because we want to call it manually to return a promise
    this.set(data, { skipSetUserData: true })
    // Sign the user in with AJAX and log the token and user info
    const json = this.toJSON()
    channel.trigger('signIn', json)
    // Fire an additional event to our lightweight message bus, used by scripts initialized early (e.g. Woopra in GTM)
    window.pc.enqueue('auth.onSignIn', json)
    // Sync data that was stored locally while the user was anonymous
    this.syncModules()
    // Caller can wait for this to resolve before leaving the page or proceeding
    return this.setUserData()
  }

  // Loads modules that have written data while the user was anonymous so they can sync with the backend
  syncModules() {
    // Don't sync for admins because they may have anonymous favorites, photos, etc that shouldn't be synced to
    // a customer's account.
    if (!this.get('admin') && this.isSignedIn()) {
      // async load modules that stored local data if they're not already loaded, to cause them to sync data
      // with the backend, even if the module wasn't already being used on the current page.
      // If a module is already loaded, it should sync on the signIn event on the channel because the async
      // require here won't do anything.
      // After every module is done syncing, it should remove itself from the unsyncedModules array in localStorage.
      // This strategy ensures at-least-once completion of syncing in case a sync is started
      // while the page is unloading.
      getUnsyncedModulesQueue().forEach((module) => {
        // Explicitly name each module allowed for async loading so webpack can create the necessary chunks
        switch (module) {
          case 'favorites/collections/favorites':
            import('@/favorites/collections/favorites')
            break
          default:
            logError('Unexpected module to sync: ' + module)
        }
      })
    }
  }

  getAuthorizationHeader() {
    const token = this.get('token')
    if (token) {
      return 'Bearer ' + token
    }
  }

  getName() {
    // If first name is defined, last name will be due to validation
    const firstName = this.get('firstName')
    if (firstName) {
      return firstName + ' ' + this.get('lastName')
    }
  }

  // Get info about the user whether they're signed in or not.
  // Use this to get user data for tracking purposes.
  getUserData() {
    // Default email is from cookies in case we only have an ECP email and the user has never signed in
    const userData = $.extend({ email: getUserEmail() }, this.toJSON())
    if (!userData.email) {
      delete userData.email
    }
    return userData
  }
}

// Return singleton
export default new Session()
