import Backbone from 'backbone'
import Item from '../models/item'

export default Backbone.Collection.extend({
  model: Item,
  getItem(id) {
    let model = this.get(id)
    if (!model) {
      model = new Item({
        id
      })
      this.add(model)
    }
    return model
  }
})
