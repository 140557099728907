export const urlParams = function (params?: string): Record<string, string> {
  const pl = /\+/g
  const search = /([^&=]+)=?([^&]*)/g
  const decode = (s: string) => decodeURIComponent(s.replace(pl, ' '))
  const query = params || window.location.search.substring(1)
  const result: Record<string, string> = {}
  let match: RegExpExecArray | null
  while ((match = search.exec(query))) {
    result[decode(match[1]!)] = decode(match[2]!)
  }
  return result
}
export const hashParams = () => urlParams(window.location.hash.substring(1))
export const removeUrlParams = function () {
  // Favor replaceState over pushState because we don't want to make a new history entry
  if (
    typeof history !== 'undefined' && history !== null
      ? history.replaceState
      : undefined
  ) {
    // http://stackoverflow.com/questions/22753052/remove-url-parameters-without-refreshing-page/40592892#40592892

    return window.history.replaceState(null, '', window.location.pathname)
  } else {
    // Favor location.replace over location.hash because we don't want to make a new history entry

    return window.location.replace('#')
  }
}

// Favor location.replace over location.hash because we don't want to make a new history entry
export const removeUrlHash = () => window.location.replace('#')
