// Returns either window.localStorage or an object that behaves the same way, but
// persists to cookies instead of localStorage. If this falls back to a cookie-based implementation,
// the key fallback will be true on the object returned.
// Derived from https://gist.github.com/remy/350433. Stripped out sessionStorage support.
// Expects pc.cookie to be loaded first but that isn't listed here to avoid a circular dependency.
// Since pc.cookie isn't called on initialization, this is OK.
// JSURL is exposed as a global and allows for compact storage in cookies vs JSON.
import cookies from '@/utils/cookies'
import 'jsurl'

export default (() => {
  try {
    // Test localStorage existence
    if (!window.localStorage) {
      throw 'exception'
    }
    // Test localStorage accessibility - needed for Safari private browsing
    const mod = 'modernizr'
    localStorage.setItem(mod, mod)
    localStorage.removeItem(mod)
    return window.localStorage
  } catch (e) {
    const cookieKey = 'pcLocalStorage'
    const cookiePath = '/'

    // Lazily initialize this so we don't use pc.cookie during initialization.
    // This helps with RequireJS circular dependency issues.
    let data

    const getData = function () {
      if (typeof data === 'undefined') {
        // Get cookie data
        const cookieData = cookies.getItem(cookieKey)
        // If we have some data decode, parse and return it
        // eslint-disable-next-line no-undef -- FIXME
        data = cookieData ? JSURL.parse(decodeURIComponent(cookieData)) : {}
      }
      return data
    }

    const setData = function (data) {
      // Convert data into JSURL compact JSON encoding and encode to accommodate for special characters
      // eslint-disable-next-line no-undef -- FIXME
      data = encodeURIComponent(JSURL.stringify(data))
      cookies.setItem(cookieKey, data, Infinity, cookiePath)
    }

    // Instead of overwriting window.localStorage, return a replacement object
    // for our code to use so that we know if there's localStorage support
    // and so third party scripts don't fill up thousands of bytes of our cookie store
    // (almost all of it) before we even write anything.
    return {
      // Lets other code know we're not using real localStorage
      fallback: true,
      length: 0,
      clear() {
        data = {}
        this.length = 0
        cookies.removeItem(cookieKey, cookiePath)
      },
      getItem(key) {
        const data = getData()
        return data[key] === undefined ? null : data[key]
      },
      key(i) {
        const data = getData()
        // Not perfect, but works
        let ctr = 0
        for (const k in data) {
          if (ctr === i) {
            return k
          } else {
            ctr++
          }
        }
        return null
      },
      removeItem(key) {
        const data = getData()
        delete data[key]
        this.length--
        setData(data)
      },
      setItem(key, value) {
        // TODO: Throw exception if there's not enough space in cookies to write this
        const data = getData()
        data[key] = value + '' // Forces the value to a string
        this.length++
        setData(data)
      }
    }
  }
})()
