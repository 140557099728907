// Start flushing and processing async events
import '@/utils/queues/pc-queues'
// Initialize visible lazy loaded images ASAP
import '@/imgix-init'
// We start with a customized lightweight Sentry (lazy) loader. Our version is modified to provide some config early,
// in case errors are encountered early on. We later force full initialization of Sentry for enhanced logging.
import '@/sentry'

export const initTier1Common = async (): Promise<void> => {
  // TODO: Avoid side effecting imports and call their initialization methods here for better control over loading
}
