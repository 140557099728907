/**
 * Sets up queues for pages to push into before the page is set up.
 * This is a basic Pub/Sub system that allows commands to be queued up before handlers are available.
 *
 * If said queue has a handler, it will handle whatever's being enqueued.
 * If said queue doesn't have a handler, it will be queued.
 * When a handler is registered, it will flush (process) anything that's hanging in that queue name.
 */

import { type QueueName } from '@/utils/queues/types'

window.pc = window.pc || {}
window.pc.queues = window.pc.queues || {}
const queues = window.pc.queues as { [name: string]: unknown[][] }
const handlers: {
  [name: string]: ((...args: unknown[]) => void) | undefined
} = {}

// Override default minimal enqueue implementation that can be inlined into the page
// to allow enqueuing before the main entry point loads.
export const enqueue = (window.pc.enqueue = function (
  queueName: QueueName,
  ...args: unknown[]
): void {
  const handler = handlers[queueName]
  if (handler) {
    handler(...args)
  } else {
    queues[queueName] = queues[queueName] || []
    // Queue is always defined on the line above
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queues[queueName]!.push(args)
  }
})

// true for names like onClick, auth.onSignIn
const isOnHandler = /(^|\.)on[A-Z][^.]+$/

export const registerHandler = function (
  queueName: QueueName,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (...args: any[]) => void
): void {
  const queue = queues[queueName]
  // Flush queue
  if (queue) {
    let args
    while ((args = queue.shift())) {
      fn(...args)
    }
  }
  // Register handler for future calls to enqueue
  const existingHandler = handlers[queueName]
  if (existingHandler && isOnHandler.test(queueName)) {
    // For on* handlers, allow registering multiple to allow for event broadcasts
    handlers[queueName] = function (...args: unknown[]) {
      // This wrapper calls the existing handler first, then the new handler,
      // so that handlers (event listeners in this case) are executed in the order they are registered.
      existingHandler(...args)
      fn(...args)
    }
  } else {
    if (process.env.NODE_ENV !== 'production' && existingHandler) {
      // Handlers should only be registered once and reregistering likely indicates a mistake
      // eslint-disable-next-line no-console
      console.warn('Reregistering handler', queueName)
    }
    // Set handler
    handlers[queueName] = fn
  }
}

// Allows handlers to be initialized asynchronously via enqueue, even before this code runs
registerHandler('registerHandler', registerHandler)
