/**
 * Converts a string from camelCase to snake_case.
 */
export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)

/**
 * Converts a string from snake_case to camelCase.
 */
export const snakeToCamelCase = (str: string) =>
  str.replace(/(?!^)_([a-z])/g, (_, firstLetter) => firstLetter.toUpperCase())
