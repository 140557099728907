// Utilities for identifying different environments

export type Environment = 'development' | 'production' | 'staging'

export const environment: Environment = (() => {
  switch (location.hostname) {
    case 'www.paperculture.com':
      return 'production'
    case 'staging.paperculture.com':
      return 'staging'
    default:
      return 'development'
  }
})()
