import $ from 'jquery'
import authChannel from '@/auth/channel'
import { signOut } from '@/services/account'
import session from '@/auth/models/session'

const redirectParams = (redirectUrl) =>
  '?' +
  $.param({
    vid: 20090115001,
    mt: 1,
    redirecturl: redirectUrl
  })

const currentPage = () =>
  window.location.pathname + window.location.search + window.location.hash

const signInUrl = (signInDestination = currentPage()) =>
  '/cards/register.html' + redirectParams(signInDestination)

const signOutUrl = (
  signInDestination = currentPage() // No need to encode anything here since it's already encoded as a param
) => '/cards/signout.html' + redirectParams(signInUrl(signInDestination))

const updateHeaderSignInOutLink = function () {
  // Don't do it on checkout pages. headTagStatic will add one without hitting the cart API.
  if (window.location.pathname.substring(0, 7) === '/cards/') {
    return
  }

  // Get the logged-in user's first name from their session. If this exists, we assume they're logged in.
  // It's possible the user may be logged into their stateless session, but their Avetti session may have
  // expired. In this case, signedIn will still be populated.
  const signedIn = session.isSignedIn()
  const $headerLinks = $('#header-links ul')
  const $headerLinksNew = $('#top-bar-new ul')
  const $myAccount = $headerLinks.find('li:contains("My Account")').first()
  const $myAccountNew = $headerLinksNew.find('a[title="My Account"]').parent()
  const $loginWelcome = $('.login-welcome')
  const $loginLink = $('.login-link')
  if (signedIn) {
    // Add 'Welcome, {firstName}' link
    const $welcome = $('<a></a>')
      .attr({
        class: 'login-welcome',
        title: session.get('email'),
        href: '/eco/my-account'
      })
      .text('Welcome, ' + session.get('firstName'))
    if ($loginWelcome.length) {
      // Replace existing ones
      $loginWelcome.replaceWith($welcome)
    } else {
      $headerLinks.prepend($('<li></li>').wrapInner($welcome))
      $headerLinksNew.prepend($('<li></li>').wrapInner($welcome))
    }
  } else {
    // Remove welcome link
    $('.login-welcome').closest('li').remove()
  }

  const isPersonalize =
    window.location.pathname.indexOf('/eco/personalize') >= 0

  // If they're on personalize, don't redirect them to personalize, since it'll error. just leave them at the register page.
  const redirectUrl = isPersonalize ? '/eco/my-account' : undefined

  const $newLoginLink = $('<a></a>')
    .attr({
      class: 'login-link',
      href: signedIn ? signOutUrl(redirectUrl) : signInUrl(redirectUrl)
    })
    .text('Sign ' + (signedIn ? 'Out' : 'In'))
  $newLoginLink.on('click', function (e) {
    // Don't let tracking see this click so there's no delay
    e.stopPropagation()

    if (signedIn) {
      // We want to proceed to the actual URL sign out URL on sign out from these URLs since
      // we don't want to leave user information on the screen.
      const redirectToSignInPage =
        isPersonalize ||
        window.location.pathname.substring(0, 15) === '/eco/my-account'

      // Sign out of Avetti if we're not redirecting and clear session in local storage either way
      signOut()

      if (!redirectToSignInPage) {
        return e.preventDefault()
      }
    } else {
      // Sign in without leaving the page
      e.preventDefault()
      return authChannel.request('signin')
    }
  })

  if ($loginLink.length) {
    // Replace existing ones
    return $loginLink.replaceWith($newLoginLink)
  } else {
    $myAccount.before($('<li></li>').wrapInner($newLoginLink))
    return $myAccountNew.before(
      $('<li></li>').wrapInner($newLoginLink.clone(true))
    )
  } // Make a clone, including the click handler
}

export default {
  redirectParams,
  signInUrl,
  signOutUrl,
  updateHeaderSignInOutLink
}
