import {
  forgotPassword as avettiForgotPassword,
  signIn as avettiSignIn,
  signOut as avettiSignOut,
  createAccount,
  changePassword
} from '@/api/avetti/account'
import {
  createUser,
  signIn,
  signOut,
  requestPasswordReset,
  resetPassword
} from '@/api/mercury/account'
import { updateEmail, updateName } from '@/api/mondrian/account'
import {
  AccountNotFound,
  DuplicatedAccount,
  ErrorParsingResponse,
  InvalidCredentials,
  UnexpectedError
} from '@/api/error'
import { useMercury } from '@/api/mercury/utils'
import { CreateAccountParameters } from '@/services/account'

/**
 * Authentication tokens what api send at successful sign in or sign up.
 * This data will be able to be usable at api calls to verify the user
 */
export interface JwtResponse {
  /**
   * AccessToken is required for every protected api call as part of the headers
   */
  accessToken: string
  /**
   * RefreshToken is stored in localhost and is required for get an accessToken.
   * This token is just valid to request an accessToken.
   */
  refreshToken?: string
}

/**
 * User data returned by api at successful sign in
 */
export interface SignInResponse extends JwtResponse {
  firstName: string
  lastName: string
}

/**
 * Send email and password to the API tier
 * Also it verifies if there's any error and choose correct error classes.
 */
export const signInAdapter = async (
  email: string,
  password: string
): Promise<SignInResponse> => {
  if (useMercury()) {
    const { firstName, lastName, accessToken, refreshToken } = await signIn(
      email,
      password
    )
    return { firstName, lastName, accessToken, refreshToken }
  } else {
    const signInResponse = await avettiSignIn(email, password)

    if (signInResponse.result.loggedIn) {
      const { firstName, lastName, token: accessToken } = signInResponse.result

      return { firstName, lastName, accessToken }
    } else if (!signInResponse.success) {
      // Convert invalid credentials from Avetti to custom error
      throw new InvalidCredentials()
    } else {
      // Wrap all other errors with a catchall
      throw new UnexpectedError(signInResponse)
    }
  }
}

/**
 * Converts the response data returned from the createAccount function into a standardized format
 *
 * @param signupParams - The parameters to be sent to the sign up request
 *
 * @throws DuplicatedAccount if the user account already exists
 * @throws Error if there is an error creating the account or parsing the response
 *
 * @returns The response data in a standardized format
 */
export const createAccountAdapter = async ({
  signupParams,
  email,
  firstName,
  lastName,
  password,
  referrer,
  referrerOther
}: CreateAccountParameters): Promise<JwtResponse> => {
  const createAccountData = {
    signupParams,
    email,
    firstName,
    lastName,
    password
  }

  if (useMercury()) {
    const { accessToken, refreshToken } = await createUser({
      ...createAccountData,
      referrer,
      referrerOther
    })
    return {
      accessToken,
      refreshToken
    }
  } else {
    const createAccountResponse = await createAccount(createAccountData)

    if (createAccountResponse.result.loggedIn) {
      return {
        accessToken: createAccountResponse.result.token
      }
    } else if (
      createAccountResponse.fieldErrors[0]?.text ===
      'Sorry, we already have a member with that email address entered.'
    ) {
      throw new DuplicatedAccount()
    } else if (createAccountResponse.fieldErrors.length) {
      throw new Error('There was an error creating your account.')
    } else {
      throw new ErrorParsingResponse(createAccountResponse)
    }
  }
}

/** Sends a forgot password request for the provided email address.
 * @throws {AccountNotFound} - If the provided email address does not correspond to an existing account.
 * @returns {Promise<void>} - Resolves when the forgot password request is successfully sent.
 */
export const forgotPasswordAdapter = async (email: string): Promise<void> => {
  if (useMercury()) {
    await requestPasswordReset(email)
  } else {
    const response = await avettiForgotPassword(email)
    if (!response.success) {
      throw new AccountNotFound()
    }
  }
}

export const resetPasswordAdapter = resetPassword
/**
 * @returns The `signOut` function.
 */
export const signOutAdapter = useMercury() ? signOut : avettiSignOut

export const changePasswordAdapter = async (
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  const changePasswordResponse = await changePassword(
    currentPassword,
    newPassword
  )

  if (!changePasswordResponse.success) {
    throw new Error('There was a problem changing the password')
  }
}

/**
 * @returns The `updateEmail` function.
 */
export const updateEmailAdapter = updateEmail

/**
 * @returns The `updateName` function.
 */
export const updateNameAdapter = updateName
