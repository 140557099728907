export const encode = (data: string) => {
  return btoa(data)
    .replace(/\+/g, '-') // Replace + with - (see RFC 4648, sec. 5)
    .replace(/\//g, '_') // Replace / with _ (see RFC 4648, sec. 5)
    .replace(/=+$/, '')
}

// Works with or without padding
export const decode = (encoded: string) => {
  const base64 = encoded
    .replace(/-/g, '+') // Reverse replace of + with -
    .replace(/_/g, '/') // Reverse replace of / with _
  return atob(base64)
}
